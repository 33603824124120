const validate = (state = {}, action) => {
  switch (action.type) {
		case 'ERROR':
			return { success: false}
    default:
			return state
  }
}


export default validate
