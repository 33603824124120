import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container'
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box'
import Ticket from './Ticket'
import Typography from '@material-ui/core/Typography'
import { Link, Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	wrapper: {
		margin: theme.spacing(1),
		height: 'auto',
		[theme.breakpoints.up('xs')]: {
      width: 'calc(100% - ('+ theme.spacing(1) +'px * 2))',
    },
		[theme.breakpoints.up('sm')]: {
			width: 'calc(50% - ('+ theme.spacing(1) +'px * 2))',
			// height: 'calc(50% - ('+ theme.spacing(1) +'px * 2))',
    },
    [theme.breakpoints.up('md')]: {
			width: 'calc(33.333% - ('+ theme.spacing(1) +'px * 2))',
    },
    [theme.breakpoints.up('lg')]: {
			width: 'calc(25% - ('+ theme.spacing(1) +'px * 2))',
    },
	},
}));

const Tickets = (props) => {
	const classes = useStyles();
	let { path } = useRouteMatch();
	let history = useHistory();
	let { tickets, auth } = props;

	return (
	<Box>
		<div style={{display: 'flex', flexWrap: 'wrap'}}>
		{
			tickets.map((i, n) => {
			if (i.public || ( auth.user && auth.user.role === 'reception' )) { return (
				<div className={classes.wrapper} key={n} id={i.id} >
				<Link to={'/tickets/' + i.id} style={{textDecoration: 'none'}}>
					<Card square={true}>
						<CardMedia image={i.image[0].src} title="" style={{ paddingTop: '100%' }}/>
						<CardContent>
							<Typography variant="subtitle1">{i.name}</Typography>
						</CardContent>
					</Card>
				</Link>
			</div>
		) } else {return null}
			})
		}
		</div>
		<Switch>
			<Route path={`${path}/:id`}>
			<Modal open={true} onClose={() => history.push('/tickets') }>
				<Box>
					<Ticket />
				</Box>
			</Modal>
			</Route>
		</Switch>
	</Box>
	//
	// <Container>
	// <Box display="flex" justifyContent="center" alignItems="center"><Typography variant="h4">No events currently listed.</Typography></Box>
	// </Container>
	//
)}

export default connect( state => ({ tickets: state.tickets, cart: state.cart, auth: state.auth }) ) (Tickets)
