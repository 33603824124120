const staticSubtypes = [
  {
    id: 'super',
    name: 'Super',
    cost: 500,
    contents: 'T-shirt, Tote, 4ZZZ Pin, Bumper Sticker, Your Name On The 4ZZZ Wall!',
    gold: true,
    goldprize: '',
    general: true,
    generalprize: '',
    monthly: true,
    monthlyprize: '',
    pack: true,
    tshirt: true,
    tshirtsize: '',
    special: false,
    nameoncard: '',
    favshow: '',
		image: process.env.PUBLIC_URL + '/supersub.jpg',
		weight: {base: 0.375, S: 0, M: 0.015, L: 0.045, XL: 0.08, XXL: 0.1},
		// weight: {base: 0},
		tax: true
  },
  {
    id: 'passionate',
    name: 'Passionate',
    cost: 140,
    contents: 'T-shirt, Tote, Bumper Sticker',
    general: true,
    generalprize: '',
    monthly: true,
    monthlyprize: '',
    gold: true,
    goldprize: '',
    daily: false,
    dailyprize: '',
    tshirt: true,
    tshirtsize: '',
    pack: true,
    special: false,
    nameoncard: '',
    favshow: '',
		image: process.env.PUBLIC_URL + '/4ZZZPassionateCardFRONT-PASSIONATE.png',
		weight: {base: 0.375, S: 0, M: 0.015, L: 0.045, XL: 0.08, XXL: 0.1},
		tax: true
  },
  {
    id: 'full',
    name: 'Full',
    cost: 80,
    contents: 'Bumper Sticker',
    tshirt: false,
    general: true,
    generalprize: '',
    monthly: true,
    monthlyprize: '',
    gold: true,
    goldprize: '',
    daily: false,
    dailyprize: '',
    pack: false,
    special: false,
    nameoncard: '',
    favshow: '',
		image: 	process.env.PUBLIC_URL + '/4ZZZSubscriberCardFRONT-FULL.png',
		weight: {base: 0},
		tax: true
  },
  {
    id: 'concession',
    name: 'Concession',
    cost: 40,
    contents: 'Bumper Sticker',
    tshirt: false,
    general: true,
    generalprize: '',
    monthly: true,
    monthlyprize: '',
    gold: true,
    goldprize: '',
    daily: false,
    dailyprize: '',
    pack: false,
    special: false,
    nameoncard: '',
    favshow: '',
		image: process.env.PUBLIC_URL + '/4ZZZSubscriberCardFRONT-CONCESSION.png',
		weight: {base: 0},
		tax: true
  },
  {
    id: 'under18',
    name: 'Under 18',
    cost: 25,
    contents: 'Bumper Sticker',
    tshirt: false,
    general: true,
    generalprize: '',
    monthly: false,
    monthlyprize: '',
    gold: false,
    goldprize: '',
    daily: false,
    dailyprize: '',
    pack: false,
    special: false,
    nameoncard: '',
    favshow: '',
		image: process.env.PUBLIC_URL + '/4ZZZSubscriberCardFRONT-u18.png',
		weight: {base: 0},
		tax: true
  },
  {
    id: 'soloartist',
    name: 'Solo Artist',
    cost: 85,
    contents: '1 week Live Read Campaign valued at >$500',
    tshirt: false,
    daily: false,
    general: true,
    generalprize: '',
    monthly: false,
    monthlyprize: '',
    dailyprize: '',
    gold: false,
    pack: false,
    special: false,
    soloartist: '',
    favshow: '',
		image: process.env.PUBLIC_URL + '/4ZZZSubscriberCardFRONT-SOLO.png',
		weight: {base: 0},
		tax: true
  },
  {
    id: 'band',
    name: 'Band',
    cost: 160,
    contents: '1 week Live Read Campaign valued at >$500',
    tshirt: false,
    daily: false,
    general: true,
    generalprize: '',
    monthly: false,
    monthlyprize: '',
    gold: false,
    pack: false,
    special: false,
    members: ['', '','', ''],
    band: '',
    favshow: '',
		image: process.env.PUBLIC_URL + '/4ZZZSubscriberCardFRONT-BAND.png',
		weight: {base: 0},
		tax: true
  },
  {
    id: 'household',
    name: 'Household',
    cost: 300,
    contents: 'Includes up to 4 sub cards and a Household Sub Pack including 4 stickers, a tote bag, tea towel, 2 stubby coolers, 4 pack 4zzz coasters, and a bottle opener!',
    tshirt: false,
    daily: false,
    general: true,
    generalprize: '',
    monthly: false,
    monthlyprize: '',
    dailyprize: '',
    gold: false,
    goldprize: '',
    pack: true,
    special: false,
    members: ['', '','', ''],
    favshow: '',
		image: process.env.PUBLIC_URL + '/4ZZZSubscriberCardFRONT-HOUSEHOLD.png',
		weight: {base: 0},
		tax: true
  },
  {
    id: 'business',
    name: 'Business',
    cost: 160,
    contents: '1 week Live Read Campaign valued at >$500',
    tshirt: false,
    daily: false,
    general: true,
    generalprize: '',
    monthly: false,
    monthlyprize: '',
    gold: false,
    pack: false,
    special: false,
    business: '',
    favshow: '',
		image: process.env.PUBLIC_URL + '/4ZZZSubscriberCardFRONT-BUSINESS.png',
		weight: {base: 0},
		tax: true
  },
  {
    id: 'pet',
    name: 'Pet',
    cost: 25,
    contents: '',
    tshirt: false,
    daily: false,
    general: false,
    monthly: false,
    monthlyprize: '',
    gold: false,
    pack: false,
    special: false,
    pet: '',
    favshow: '',
		image: process.env.PUBLIC_URL + '/4ZZZSubscriberCardFRONT-PET.png',
		weight: {base: 0},
		tax: true
  },
  {
    id: 'pay-it-forward',
    name: 'Pay It Forward',
    cost: 40,
    contents: '',
    tshirt: false,
    daily: false,
    general: false,
    generalprize: '',
    monthly: false,
    monthlyprize: '',
    gold: false,
    pack: false,
    special: false,
    pet: '',
    favshow: '',
		image: process.env.PUBLIC_URL + '/4ZZZSubscriberCardFRONT-PAYITFORWARD.png',
		weight: {base: 0},
		tax: true
  }
]

export default staticSubtypes
