import validate from 'validate.js'
import { contactConstraintsNew, contactConstraintsReturning, passwordConstraints } from './constraints'
import moment from 'moment';

export const getMerch = text => ({
  type: 'GET_MERCH'
})

export const dropItem = (item) => ({
	type: 'DROP_ITEM',
	item
})

export const addToCart = (product, param, quantity) => {
	let weight = 0
	if (product.weight) {
		weight += product.weight.base;
		Object.values(param).forEach(
			i => { if (i in product.weight) { weight += product.weight[i] } }
		);
	}
	// weight = weight * quantity

	return ({
  type: 'ADD_TO_CART',
  product,
	param,
	quantity,
	weight
})}

export const clearAuth = () =>  ( (dispatch) => {
	dispatch(removeContact())
	dispatch(setActiveSub(false))
	dispatch(clearSubErrors())
	localStorage.removeItem('user')
	localStorage.removeItem('token')
	dispatch ({ type: 'CLEAR_AUTH' })
})

export const rejectAuth = () => ( { type: 'REJECT_AUTH' })

export const setAuth = (token, user) => ( (dispatch) => {
	let admin = false
	if (user.role === 'zedder')
		dispatch(addContact(user))
	if (user.role === 'reception')
		admin = true
	if ( user.expiry && moment(user.expiry).isSameOrAfter() ) {
		dispatch(setActiveSub(true))
	}
	localStorage.setItem('token', token)
	localStorage.setItem('user', JSON.stringify(user))
	dispatch ({
		type: 'SET_AUTH',
		token,
		user,
		admin
	})
})


export const setSection = () => ( { type: 'SET_SECTION'} )

export const getSubtypes = text => ({
  type: 'GET_SUBTYPES'
})

export const getDailyPrizes = text => ({
  type: 'GET_DAILY_PRIZES'
})

export const getAprilAtonementPrizes = text => ({
  type: 'GET_APRIL_ATONEMENT_PRIZES'
})

export const getMonthlyPrizes = text => ({
  type: 'GET_MONTHLY_PRIZES'
})

export const getGoldPrizes = text => ({
  type: 'GET_GOLD_PRIZES'
})

export const setCurrentSub = text => ({
	type: 'SET_CURRENT_SUB'
})

export const addSubscription = subscription => ({
	type: 'ADD_SUBSCRIPTION',
	subscription
})

export const fetchAndAddSubscription = (subscription) => {
	return (dispatch, getState) => {
		let pos = getState().subscriptions.subtypes.map( e => ( e.id )).indexOf(subscription)
		let subtype = getState().subscriptions.subtypes[pos]
		dispatch(addSubscription(subtype))
	}
}

export const removeSubscription = subscription => ({
	type: 'REMOVE_SUBSCRIPTION',
	subscription
})

export const subDiscount = discount => ({
	type: 'SUB_DISCOUNT',
	discount
})

const addShows = shows => ({
	type: 'ADD_SHOWS',
	shows
})

export const getShows = () => {
	return (dispatch, getState) => {
  	fetch('https://data.4zzz.org.au/zeddb/programs').then(res=>res.json()).then(response => dispatch(addShows(response)) ).catch(error => console.error('Error:', error))
	}
}

export const addSubToCart = (subscription, param, quantity) => ({
  type: 'ADD_SUB_TO_CART',
  subscription,
	param,
	quantity
})

export const setActiveSub = (value) => ({
	type: 'SET_ACTIVE_SUB',
	value
})

export const stationPickup = (value) => ({
	type: 'STATION_PICKUP',
	value
})

export const addDonation = (donation) => ( dispatch => {
	if (donation > 0) {
		dispatch(addToCart({name: 'One Time Donation', image: process.env.PUBLIC_URL + '/DonationCard.png', price: [donation, donation],
		tax: false, type: 'donation'}, {}, 1))
		dispatch ({
			type: 'ADD_DONATION',
			donation
		})
	}
} )

const setZedletter = (value) => ({
	type: 'SET_ZEDLETTER',
	value
})

export const hideDialog = () => ({
	type: 'HIDE_DIALOG',
})

export const toggleZedletter = () => {
	return (dispatch, getState) => {
		let { contact, auth} = getState();
		fetch('https://support.4zzz.org.au/api/toggle-zedletter', {
			method: 'POST', // or 'PUT'
			body: JSON.stringify( { contact: contact } ), // data can be `string` or {object}!
			headers:{
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + auth.token
			}
		}).then( res => {
			if(res.status !== 200) {
				throw new Error(res.status)
			} else {
				return res.json()
			}
		}).then( ( response ) =>  {
			dispatch(setZedletter(response.zedletter))
		}).catch(function(error)
		{
			if (error.message === '401') {
			}
			console.log(error)
		});
	}
}

export const addContact = (contact) => ({
	type: 'ADD_CONTACT',
	contact
})

export const removeContact = () => ({
	type: 'REMOVE_CONTACT',
})


export const editContact = (key, value) => ({
	type: 'EDIT_CONTACT',
	key,
	value
})

export const setContact = (contact) => ({
	type: 'SET_CONTACT',
	contact
})

export const editPassword = (key, value) => ({
	type: 'EDIT_PASSWORD',
	key,
	value
})

export const setCompleted = (step) => ({
	type: 'SET_COMPLETED',
	step
})

export const setActiveStep = (step) => ({
	type: 'SET_ACTIVE_STEP',
	step
})

const setSubError = ( id, error ) => ({
	type: 'SET_SUB_ERROR',
	id,
	error
})

const unsetSubError = ( id ) => ({
	type: 'UNSET_SUB_ERROR',
	id,
})

export const clearSubErrors  = () => ({
	type: 'CLEAR_SUB_ERRORS'
})

const unsetPasswordError = ( id ) => ({
	type: 'UNSET_SUB_ERROR',
	id,
})

const setPasswordError = ( id, error ) => ({
	type: 'SET_PASSWORD_ERROR',
	id,
	error
})

const formValidity = ( id, value ) => ({
	type: 'FORM_VALIDITY',
	id,
	value
})

export const validatePassword = (id, value, context) => (
	(dispatch, getState) => {
	let passwords = getState().passwords;
	let error = getState().error;
	// let err = validate({[id]: value}, passwordConstraints[id])
	// console.log( {[id]: value})

	let err = validate(passwords, passwordConstraints)

	if (err && err[id]) {
		dispatch(setPasswordError(id, err[id]))
	} else if (error[id]) {
		dispatch(unsetPasswordError(id))
	}
	if (!err) {
		// dispatch( formValidity( 'passwords', true ))
	} else {
		// dispatch( formValidity( 'passwords', false ))
	}

})

export const validateContact = (id, value, context, step = 0) => ( (dispatch, getState) => {
	let contact = getState().contact;
	let error = getState().error;
  let subConstraints = (context === 'new') ? contactConstraintsNew : contactConstraintsReturning
	validate.async({[id]: value}, subConstraints).then((success) => {
		console.log(success[id])
	}, (err) => {
		console.log(err)
		if (err[id]) {
			dispatch(setSubError(id, err[id]))
		} else if (error[id]) {
			dispatch(unsetSubError(id))
		}
	} )
	validate.async(contact, subConstraints).then((success) => {
		if (!success) {
			// dispatch( formValidity( 'contact', false ))

		}
		else {
			// dispatch( formValidity( 'contact', true ))
			// dispatch(setCompleted( step ))
			// dispatch(setActiveStep( step + 1))
		}
	}, (err) => console.log(err) )
})

export const confirmOrder = () => ( (dispatch, getState) => {
	let { contact, auth, cart} = getState();
	fetch('https://support.4zzz.org.au/api/confirm-order', {
		method: 'POST', // or 'PUT'
		body: JSON.stringify( { contact: contact, cart: cart } ), // data can be `string` or {object}!
		headers:{
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + auth.token
		}
	}).then( res => {
		if(res.status !== 200) {
			throw new Error(res.status)
		} else {
			return res.json()
		}
	}).then( ( response ) =>  {
		dispatch(orderDetails(response))
		// dispatch(snack('success', 'Your order has been confirmed!'))
		dispatch(setCompleted( 1 ))
		dispatch(setActiveStep( 2 ))
		// this.props.authHandler('set', response.token, response.user)
	}).catch(function(error)
	{
		if (error.message === '401') {
			dispatch(snack('error', 'Not authorized, are you logged in?'))
			// dispatch(rejectAuth())
		}
		else {
			dispatch(snack('error', 'Server could not be reached!'))
		}
		console.log(error)
	});
})

const clearCart = () => ({
	type: 'CLEAR_CART'
})

const clearContact = () => ({
	type: 'CLEAR_CONTACT'
})

export const clearReceptionOrder = () => ( (dispatch) => {
	dispatch(clearCart())
	dispatch(clearContact())
})

const initRt = (response) => ({
	type: 'INIT_RT',
	response
})

export const rtFormat = (value) => ({
	type: 'RT_FORMAT',
	value
})

export const editRTContact = (key, value) => ({
	type: 'EDIT_RT_CONTACT',
	key,
	value
})

export const fetchContactFromToken = (token) => ( (dispatch, getState) => {
	fetch('https://support.4zzz.org.au/api/radio-times', {
		method: 'POST', // or 'PUT'
		body: JSON.stringify( { token: token } ), // data can be `string` or {object}!
		headers:{
			'Content-Type': 'application/json',
		}
	}).then( res => {
		if(res.status !== 200) {
			throw new Error(res.status)
		} else {
			return res.json()
		}
	}).then( ( response ) =>  {
		response.mail_radio_times = String(response.mail_radio_times)
		dispatch(initRt(response));
		// this.props.authHandler('set', response.token, response.user)
	}).catch(function(error)
	{
		if (error.message === '422') {
			dispatch(snack('error', 'The token you used is incorrect or expired.'))
			// dispatch(rejectAuth())
		}
		else {
			dispatch(snack('error', 'Server could not be reached!'))
		}
		console.log(error)
	});
})

export const emailInvoice = (transactionId) => ( (dispatch, getState) => {
	let { auth} = getState();
	fetch('https://support.4zzz.org.au/api/email-invoice', {
		method: 'POST', // or 'PUT'
		body: JSON.stringify( { transactionId: transactionId } ), // data can be `string` or {object}!
		headers:{
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + auth.token
		}
	}).then( res => {
		if(res.status !== 200) {
			throw new Error(res.status)
		} else {
			return res.json()
		}
	}).then( ( response ) =>  {
		dispatch(snack('success', 'Your invoice has been sent'))
		// this.props.authHandler('set', response.token, response.user)
	}).catch(function(error)
	{
		if (error.message === '401') {
			dispatch(snack('error', 'Not authorized, are you logged in?'))
			// dispatch(rejectAuth())
		}
		else {
			dispatch(snack('error', 'Server could not be reached!'))
		}
		console.log(error)
	});
})

export const addValidContact = () => ( (dispatch, getState) => {
	let { contact, passwords } = getState();
	const userDetails = { ...contact, ...passwords }
	console.log(userDetails)

	fetch('https://support.4zzz.org.au/api/create-contact', {
		method: 'POST', // or 'PUT'
		body: JSON.stringify( userDetails ), // data can be `string` or {object}!
		headers:{
			'Content-Type': 'application/json',
		}
	}).then( res => {
		if(res.status !== 200) {
			throw new Error(res.status)
		} else {
			return res.json()
		}
	}).then( ( response ) =>  {
		dispatch(setAuth(response.token, response.zedder))
		dispatch(snack('success', 'New contact created'))
		// this.props.authHandler('set', response.token, response.user)
	}).catch(function(error)
	{
		if (error.message === '401') {
			dispatch(snack('error', 'Not authorized, are you logged in?'))
			// dispatch(rejectAuth())
		} else {
			dispatch(snack('error', 'Server could not be reached!'))
		}
		console.log(error)
		///if status code 401...
	});
})

export const updateValidContact = () => ( (dispatch, getState) => {
	let { contact, auth } = getState();
	console.log(contact)

	fetch('https://support.4zzz.org.au/api/update-contact', {
		method: 'POST', // or 'PUT'
		body: JSON.stringify( contact ), // data can be `string` or {object}!
		headers:{
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + auth.token
		}
	}).then( res => {
		if(res.status !== 200) {
			throw new Error(res.status)
		} else {
			return res.json()
		}
	}).then( ( response ) =>  {
		dispatch(snack('success', 'Contact information updated'))
		// this.props.authHandler('set', response.token, response.user)
	}).catch(function(error)
	{
		if (error.message === '401') {
			dispatch(snack('error', 'Not authorized, are you logged in?'))
			// dispatch(rejectAuth())
		} else {
			dispatch(snack('error', 'Server could not be reached!'))
		}
		console.log(error)
		///if status code 401...
	});
})

export const validateContactForm = ( context ) => ( (dispatch, getState) => {
	let { contact, passwords} = getState()
	console.log(contact)
	let constraints = (context === 'new') ? { ...contactConstraintsNew, ...passwordConstraints } : contactConstraintsReturning
	let attributes = (context === 'new') ? { ...contact, ...passwords } : contact
	validate.async(attributes, constraints).then((success) => {
		if (!success) {
			dispatch( formValidity( 'contact', false ))
		}
		else {
			dispatch( formValidity( 'contact', true ))
			if (context === 'new')
				dispatch( addValidContact() )
			else
				dispatch( updateValidContact() )
			dispatch(setCompleted( 0 ))
			dispatch(setActiveStep( 1 ))
		}
	}, (err) => {
		dispatch( formValidity( 'contact', false ))
		console.log(err)
		Object.keys(err).forEach((id) => {
			dispatch(setSubError(id, err[id]))
		})
	})
	// if (context === 'new') {
	// 	let passwords = getState().passwords;
	// 	let error = getState().
	// 	 error;
	// 	console.log(passwords)
	// 	let err = validate(passwords, passwordConstraints)
	// 	console.log(err)
	// 	if (err) {
	// 		Object.keys(err).forEach((id) => {
	// 			dispatch(setPasswordError(id, err[id]))
	// 		})
	// 	}
	// }
})

const addCountries = (countries) => ({
	type: 'ADD_COUNTRIES',
	countries
})

export const getCountries = () => ( (dispatch, getState) => {
	fetch('https://support.4zzz.org.au/api/countries').then(res=>res.json()).then(response => dispatch(addCountries(response)) ).catch(error => console.error('Error:', error))
})

const addMerchInventory = (merch) => ({
	type: 'ADD_MERCH_INVENTORY',
	merch
})

export const getMerchInventory = () => ( (dispatch, getState) => {
	fetch('https://support.4zzz.org.au/api/merch').then(res=>res.json()).then(response => dispatch(addMerchInventory(response)) ).catch(error => console.error('Error:', error))
})

export const clearState = () => ({
	type: 'CLEAR_STATE'
})

export const snack = (status, message) => ({
	type: 'SNACK',
	status,
	message
})

export const orderDetails = (order) => ({
	type: 'ORDER_DETAILS',
	order
})
