import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { clearState } from '../actions'
const PaymentSuccess = (props) => {
	const { dispatch } = props
	useEffect(() => {
		setTimeout(
			() => {
				dispatch(clearState())
			},
			6000
		);
 	}, [dispatch]);


	return (
	<Container>
		<Typography variant="h5">
			Thank you!
		</Typography>

		<p>
			Thank you for your order, you should receive a tax invoice to your email address shortly.
		</p>

		<p>
			All orders are processed by 4ZZZ volunteers and will be dispatched at the earliest available opportunity. Please allow up to 14 days for delivery.
		</p>

		<p>
			If you wish to follow up on your order, or if you did not recieve a confirmation email, please contact reception@4zzz.org.au
		</p>
	</Container>
)}

export default connect() (PaymentSuccess)
