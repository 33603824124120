import React from 'react'
import { connect } from 'react-redux'
import { useHistory, Link } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar'
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import MenuComponent from './Menu'

import { clearAuth, clearState } from '../actions'
const NavBar = (props) => {

	const { cart, auth, dispatch } = props
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menu, setMenu] = React.useState(false);

	let history = useHistory();
	const open = Boolean(anchorEl);
	const theme = useTheme();
	let mdUp = useMediaQuery(theme.breakpoints.up('md'))

	function handleClose(event) {
		console.log(event.currentTarget.id)
		setAnchorEl(null);
		if(event.currentTarget.id === 'login') {
			history.push("/login");
		}
		if(event.currentTarget.id === 'zedletter') {
			history.push("/zedletter");
		}
		if(event.currentTarget.id === 'account' || event.currentTarget.id === 'profile') {
			history.push("/account");
		}
		if(event.currentTarget.id === 'logout') {
			dispatch(clearAuth())
		}
		if (event.currentTarget.id === 'something') {
			fetch('https://support.4zzz.org.au/api/test', {
				method: 'GET', // or 'PUT'
				headers:{
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('token')
				}
			}).then( res => {
				if(res.status !== 200) {
					throw new Error(res.status)
				} else {
					return res.json()
				}
			}).then( response =>  console.log(response)).catch(function(error) {
				if (error.message === '401') {
				}
				console.log(error)
				///if status code 401...
			})
		}
	}
  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  return(
      <div>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <AppBar position="static">
        <Container>

          <Toolbar disableGutters={true}>
							<Tooltip title="Info">
              <Link to="/"><img alt="4ZZZ Logo" style={{ height: '48px', width: '48px', paddingRight: '10px', lineHeight: '48px' }}src={process.env.PUBLIC_URL + '/4zzz.svg'} /></Link>
							</Tooltip>
              <Typography variant="h6" color="inherit" style={{flexGrow: 1}}>
              </Typography>


							<Tooltip title="Cart">
								<IconButton
								id="cart-button"
								color="inherit"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								aria-label="menu"
								onClick={ () => { if(mdUp) return; history.push("/cart") } }
								>
									<Badge badgeContent={cart.items.length} color="secondary">
										<ShoppingCartIcon />
									</Badge>
								</IconButton>
							</Tooltip>

							<Tooltip title="Account">
								<IconButton
									id="account-button"
									color="inherit"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									aria-label="menu"
									onClick={handleMenu}
								>
									<AccountCircle />
								</IconButton>
							</Tooltip>

								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={open}
									onClose={handleClose}
								>
									{ auth.user && <MenuItem id="profile" onClick={handleClose}>Profile</MenuItem> }
									{ auth.user && <MenuItem id="zedletter" onClick={handleClose}>Zedletter Options</MenuItem> }
									{ auth.user && <MenuItem id="logout" onClick={handleClose}>Logout</MenuItem> }
									{ !auth.user && <MenuItem id="login" onClick={handleClose}>Login</MenuItem>}
									<MenuItem id="clear" onClick={() => dispatch(clearState())}>Clear</MenuItem>
								</Menu>

							<Tooltip title="Menu">
								<IconButton
									id="account-button"
									color="inherit"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									aria-label="menu"
									onClick={() => setMenu(true)}
								>
									<MenuIcon />
								</IconButton>
							</Tooltip>

          </Toolbar>
        </Container>

      </AppBar>
			<Drawer anchor="left" open={menu}	onClose={() => {setMenu(false)}}>
				<MenuComponent />
			</Drawer>
      </div>
  )
}

export default connect(state => ({ cart: state.cart, auth: state.auth })) (NavBar)
