const phoneForm = (state = {
	activeStep: 0,
	completed: { 0: false, 1: false, 2: false, 3: false }
}, action) => {
  switch (action.type) {
		case 'SET_ACTIVE_STEP':
			return { ...state, activeStep: action.step }
		case 'SET_COMPLETED':
			return { ...state, completed: {...state.completed, [action.step]: true} }
    default:
			return state
  }
}

export default phoneForm
