import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Snacks = (props) => {
	const { cart, error } = props
	const snack = error.snack
	let cartCount = cart.items.length
	const prevCount = usePrevious(cartCount)

	const [ open, setOpen ] = useState(false)
	const [ alertOpen, setAlertOpen ] = useState(false)
	const [ message, setMessage ] = useState('')
	const handleClose = () => {
		setOpen(false);
	}

	const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

	useEffect( () => {
		if ( prevCount > cartCount) {
			setMessage('Removed from cart')

		}
		else if ( prevCount < cartCount) {
			setMessage('Added to cart')
		}
		else {
			return
		}
		setOpen(true)
		setTimeout( () => setOpen(false), 5000 )
	}, [cartCount, prevCount])

	useEffect( () => {
		if (Object.keys(snack).length)
			setAlertOpen(true)
	}, [snack] )

	return (
		<>
		<Snackbar onClick={handleClose}
			open={open}
			onClose={handleClose}
			message={message}
		/>
		<Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
		  <Alert onClose={handleAlertClose} severity={snack.status}>
		    {snack.message}
		  </Alert>
		</Snackbar>
		</>
	)
}

export default connect( state => ({ error: state.error, cart: state.cart })) ( Snacks )
