import React from 'react'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	menu: {
		width: 'calc(25%)',
	},
	topIcon: {
		backgroundColor: theme.palette.primary.main
	}

}))

function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const Menu = (props) => {
	const classes = useStyles();
	const version = '2024.sick';
	const { auth } = props
	return (
		<>
			<Box style={{flexGrow: 1}}>
				<Box className={classes.topIcon}>
				<img alt="4ZZZ Logo" style={{ height: 'auto', width: 'calc(100% - 60px)', margin: '30px', lineHeight: '48px' }}src={process.env.PUBLIC_URL + '/4zzz.svg'} />
				</Box>
				<Container>
					<Typography variant="h6" color="inherit">
						Support 4ZZZ
					</Typography>
					<Typography variant="subtitle1" color="inherit">
						Subscriptions & Merch
					</Typography>
					<Typography fontWeight="fontWeightLight"  variant="caption">v{version}</Typography>
				</Container>
			</Box>
			<Box style={{marginBottom: 56}}>
					{ auth && auth.user &&
						<Box style={{padding: '16px 16px'}}>
							<Typography variant="body1" component="p">{auth.user.name}</Typography>
							<Typography variant="subtitle1" component="p"># {auth.user.subnumber}</Typography>
							<Typography variant="subtitle2" component="p">expiry: {auth.user.expiry}</Typography>
						</Box>
					}
					<Divider />
					<List aria-label="subscriptions donations merch tickets">
						<ListItemLink to="/subscriptions" primary="Subscriptions" />
						<ListItemLink to="/donations" primary="Donations" />
						<ListItemLink to="/merch" primary="Merch" />
						<ListItemLink to="/tickets" primary="Tickets" />
					</List>
					<Divider />
					<List aria-label="subscriptions privacy-policy shipping-returns-policy">
						<ListItemLink to="/privacy-policy" primary="Privacy Policy" button />
						<ListItemLink to="/shipping-returns-policy" button primary="Shipping & Returns Policy" />
					</List>



			</Box>
		</>
	)
}

export default connect(state => ({auth: state.auth})) (Menu)
