import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CloseIcon from '@material-ui/icons/Close';
import FormLabel from '@material-ui/core/FormLabel';
import { dropItem, addDonation } from '../actions'

const useStyles = makeStyles((theme) => ({
  formControl: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
    minWidth: '50%',
  },
  donationButton: {
    marginRight: theme.spacing(2),
  }
}));
const ColorButton = withStyles((theme) => ({
  root: {
    height: '56px',
		borderRadius: 0
  },
}))(Button);

function Cart(props) {
	let { cart, dispatch } = props
	let history = useHistory();
	const [donation, setDonation] = useState(50);
	const [donationsDialog, setDonationsDialog] = React.useState(false);
	const classes = useStyles();

  const handleClose = (donate) => {
		setDonationsDialog(false);
		if (donate)
		{
			dispatch(addDonation(Number(donation) ))
		}
		else {
			history.push('/checkout')
		}
  };

	return (
		<React.Fragment>
			<Dialog open={donationsDialog} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Make a donation?</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Throw in a little extra this Radiothon with a tax deductible donation. As a volunteer driven community organisation every cent helps and we’ll make your donation count. Select one of the suggested amounts below or enter your own.
					</DialogContentText>
          <div>
            <Button className={classes.donationButton} size="large" onClick={() => setDonation(5)} variant="contained">
  						$5
  					</Button>
            <Button className={classes.donationButton} size="large" onClick={() => setDonation(50)} variant="contained">
  						$50
  					</Button>
            <Button className={classes.donationButton} size="large" onClick={() => setDonation(500)} variant="contained">
  						$500
  					</Button>
          </div>
					<FormControl className={classes.formControl} variant="outlined">
						<InputLabel htmlFor="outlined-adornment-amount">Donation Amount</InputLabel>
						<OutlinedInput
							id="outlined-adornment-amount"
							value={donation}
							type="number"
							onChange={e => setDonation(e.target.value)}
							startAdornment={<InputAdornment position="start">$</InputAdornment>}
							labelWidth={140}
						/>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose(false)} variant="outlined" color="primary">
						No thanks
					</Button>
					<Button onClick={() => handleClose(true)} variant="contained" color="secondary">
						Donate
					</Button>
				</DialogActions>
			</Dialog>
			<Box style={{flexGrow: 1, padding: '0 1em', overflowY: 'auto'}}>
				<Grid justify="flex-end" container spacing={2}>
				{ 	cart.items.map((c, n) =>
					<React.Fragment key={n}>
						<Grid item xs={1}>
							<IconButton style={{padding: '4px'}} color="primary" aria-label="delete item" component="span" onClick={e => dispatch(dropItem(n))}>
								<CloseIcon style={{ fontSize: 18, color: '#ccc' }} />
							</IconButton>

						</Grid>
						<Grid item xs={9}>

						<Typography>{c.name}</Typography>
						{c.param && Object.keys(c.param).map(n => <div style={{fontSize: '85%', color: '#ccc'}} key={n}>{c.param[n]}</div> )}
						</Grid>
						<Grid item xs={2}>
						<Typography align="right">
						{c.itemPrice}
						</Typography>

						</Grid>
					</React.Fragment>
				)}
				</Grid>
			</Box>
			<Box style={{ padding: '1em 1em'}}>

				<Grid container justify="flex-end" spacing={2}>
					<Grid item xs={10}>
						<FormLabel style={{lineHeight: '42px'}}>Sub Discount</FormLabel>
					</Grid>
					<Grid item xs={2} style={{paddingRight: 0, display: 'flex', justifyContent: 'flex-end' }}>
						<Checkbox
								disabled
				        checked={cart.subDiscount}
				        inputProps={{ 'aria-label': 'primary checkbox' }}
				      />
					</Grid>

					<Grid item xs={10}>
					<Typography variant="h5">
						Sub Total
					</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant="h5" align="right">
							{cart.subTotal}
						</Typography>
					</Grid>

					{ cart.totalWeight && cart.totalWeight !== 0 ?
					<>
						<Grid item xs={10}>
						<Typography variant="caption">
							Total Weight {parseFloat(cart.totalWeight).toFixed(2)}kg
						</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography align="right">
							</Typography>
						</Grid>

						<Grid item xs={10}>
						<Typography variant="caption">
							Shipping calculated at checkout
						</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography align="right">
							</Typography>
						</Grid>

					</>
					:
					null
					}


				</Grid>
			</Box>
			<Box><ColorButton disabled={cart.items.length === 0} color="secondary" variant="contained" fullWidth={true} size="large" onClick={() => {
				if ( cart.items.find(i => i.type === 'donation') !== undefined ) {
					history.push('/checkout') }
				else {
					setDonationsDialog(true)
				}
			}} >Checkout</ColorButton></Box>

		</React.Fragment>
	)
}

export default connect( state => ({ merch: state.merch, cart: state.cart }) ) (Cart)
