import React, { useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PayPal from './PayPal';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { snack, orderDetails } from '../actions';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="#### #### #### ####" mask="_"
    />
  );
}
NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function CreditCardCVV(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="###" placeholder="123" mask="_"
    />
  );
}

function CreditCardExpiry(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="##/##" placeholder="MM/YY" mask={['M', 'M', 'Y', 'Y']}
    />
  );
}


const useStyles = makeStyles((theme) => ({
	methodHeading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightBold,
		textTransform: 'uppercase',
	}
}))

const Payment = (props) =>
{
	const { auth, cart, contact, approvedHandler, dispatch } = props
	const [ method, setMethod ] = useState('eftpos')
	const [ ccnumber, setCcnumber ] = useState('')
	const [ ccexpiry, setCcexpiry ] = useState('')
	const [ cccvv, setCccvv ] = useState('')
	const [ cash, setCash ] = useState('')
	const [ ccname, setCcname ] = useState('')
	const [ expanded, setExpanded ] = React.useState(false);
	const [ sending, setSending ] = useState(false)
	const [ ccerror, setCcerror ] = useState('')

 	const classes = useStyles();

  const handleChange = (panel) => (event, isExpanded) => {
		if(panel === 'paypal') {
			fetch('https://support.4zzz.org.au/api/reception-order', {
				method: 'POST', // or 'PUT'
				body: JSON.stringify( { contact: contact, cart: cart, paymentMethod: 'PayPal' } ), // data can be `string` or {object}!
				headers:{
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + auth.token
				}
			}).then( res => {
				if(res.status !== 200) {
					throw new Error(res.status)
				} else {
					return res.json()
				}
			}).then( ( response ) =>  {
				dispatch(orderDetails(response.order))
				setMethod(isExpanded ? panel : false);

			})
		}
		else {
			setMethod(isExpanded ? panel : false);
		}
  };

	const validatePayment = (event) => (section) => {
		console.log(event, section)
	}

	const sendReceptionPayment = (paymentMethod) => (event ) => {
		console.log(contact, cart);
		setSending(true)

		fetch('https://support.4zzz.org.au/api/reception-order', {
			method: 'POST', // or 'PUT'
			body: JSON.stringify( { contact: contact, cart: cart, paymentMethod: paymentMethod } ), // data can be `string` or {object}!
			headers:{
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + auth.token
			}
		}).then( res => {
			if(res.status !== 200) {
				throw new Error(res.status)
			} else {
				return res.json()
			}
		}).then( ( response ) =>  {
			dispatch(snack('success', 'Order confirmed'))
			setSending(false)
			approvedHandler(response)
		}).catch(function(error)
		{
			setSending(false)
			if (error.message === '401') {
				dispatch(snack('error', 'Not authorized, are you logged in?'))
				// dispatch(rejectAuth())
			}
			else {
				dispatch(snack('error', 'Server could not be reached!'))
			}
			console.log(error)
		});
	}

	const sendCCPayment = (event) => {
		setSending(true)
		setCcerror('')

		let paymentRequest = {
		  "requestType": "PaymentCardSaleTransaction",
		  "transactionAmount": {
		    "total": cart.total,
		    "currency": "AUD"
		  },
		  "paymentMethod": {
		    "paymentCard": {
		      "number": ccnumber,
		      "securityCode": cccvv,
		      "expiryDate": {
		        "month": ccexpiry.slice(0, 2),
		        "year": ccexpiry.slice(2, 4)
		      }
		    }
		  },
			"transactionOrigin": "ECOM",
		}

		var url = 'https://support.4zzz.org.au/api/test';
		fetch(url, {
			method: 'POST', // or 'PUT'
			body: JSON.stringify(paymentRequest), // data can be `string` or {object}!
			headers:{
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + auth.token
			}
		})
		.then(res => {
				if (res.status >= 400 && res.status < 600) {
					return res.json().then( response => {
						console.log(response);
						let errmsg = response.error.message + '\r '
						response.error.details && response.error.details.forEach ( d => { errmsg += d.field + ' - ' + d.message + '\r ' })
						throw new Error(errmsg) } )

	    }
			return res.json()
		})
		.then(response => {
			setSending(false)
			approvedHandler(response)
			console.log('WHAT')
		} )
		.catch(error => {

			console.log('did we error?');
			console.error('Error:', error)
			setCcerror(error.message)
			setSending(false)
		});

		event.preventDefault()
	}

	const error = { ccnumber: false }

return (
  <>
	<Box>
	<Box textAlign="center"><Typography variant="h4">$ {cart.total}</Typography></Box>
	<Accordion expanded={method === 'paypal'} onChange={handleChange('paypal')}>
		<AccordionSummary
			aria-controls="panel4bh-content"
			id="panel4bh-header"
		>
			<Typography className={classes.methodHeading}	>Credit Card (paypal)</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<Box>
				{ method === 'paypal' && <PayPal /> }
			</Box>
		</AccordionDetails>
	</Accordion>
  <Accordion expanded={method === 'eftpos'} onChange={handleChange('eftpos')}>
    <AccordionSummary
      aria-controls="panel2bh-content"
      id="panel2bh-header"
    >
      <Typography className={classes.methodHeading}>Eftpos</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Box>
				<Typography variant="caption" component="div">
        	Key in Eftpos terminal and continue only when payment is approved.
      	</Typography>
				<Button disabled={sending} variant="contained" color="secondary" onClick={sendReceptionPayment('Eftpos')}>{sending ? 'Sending' : 'EFTPOS Approved'}</Button>
			</Box>
    </AccordionDetails>
  </Accordion>
  <Accordion expanded={method === 'cash'} onChange={handleChange('cash')}>
    <AccordionSummary
      aria-controls="panel3bh-content"
      id="panel3bh-header"
    >
      <Typography className={classes.methodHeading}>Cash</Typography>
    </AccordionSummary>
    <AccordionDetails>
			<Box>
				<TextField
					id="cash-taken"
					label="Cash Taken"
					value={cash}
					onChange={(event) => setCash(event.target.value)}
					onBlur={validatePayment('cash')}
					margin="normal"
				/>
				<Typography variant="h6">Change: {cash - cart.total}</Typography>
				<Button disabled={sending} variant="contained" color="secondary" onClick={sendReceptionPayment('Cash')}>{sending ? 'Sending' : 'Take Payment'}</Button>
			</Box>
    </AccordionDetails>
  </Accordion>

  <Accordion expanded={method === 'pledge'} onChange={handleChange('pledge')}>
    <AccordionSummary
      aria-controls="panel4bh-content"
      id="panel4bh-header"
    >
      <Typography className={classes.methodHeading}	>Pledge</Typography>
    </AccordionSummary>
    <AccordionDetails>
			<Box>
				<Typography variant="caption" component="div">
					Pledges must be fully paid by 4PM Monday September 14 to be eligible for your nominated prizes. Please note that pledges cannot be paid online, only by calling or visiting 4ZZZ reception during our opening hours.
				</Typography>
				<Button disabled={sending} variant="contained" color="secondary" onClick={sendReceptionPayment('Pledge')}>{sending ? 'Sending' : 'Make Pledge'}</Button>
			</Box>
    </AccordionDetails>
  </Accordion>
	<Accordion expanded={method === 'credit-card'} onChange={handleChange('credit-card')}>
		<AccordionSummary
			aria-controls="panel1bh-content"
			id="panel1bh-header"
		>
			<Typography className={classes.methodHeading}>Credit Card - NOT WORKING YET</Typography>
		</AccordionSummary>
		<AccordionDetails>
			<Box>
				<form onSubmit={sendCCPayment}>
				<Grid spacing={2} container>
					<Grid item xs={6} sm={6} lg={6} xl={6}>
						<TextField
							label="Credit Card Number"
							value={ccnumber}
							placeholder="0000 1111 2222 3333"
							onChange={ (event) => setCcnumber(event.target.value) }
							onBlur={validatePayment('ccnumber')}
							error={(error && error.ccnumber) ? true: false  }
							helperText={error && error.ccnumber ? error.ccnumber : '' }
							id="credit-card-number"
							fullWidth
							InputProps={{
								inputComponent: NumberFormatCustom,
							}}
						/>
					</Grid>
					<Grid item xs={4} sm={4} lg={4} xl={4}>
						<TextField
							label="Expiry"
							value={ccexpiry}
							onChange={(event) => setCcexpiry(event.target.value)}
							onBlur={validatePayment('ccexpiry')}
							fullWidth
							id="credit-card-expiry"
							InputProps={{
								inputComponent: CreditCardExpiry,
							}}
						/>
					</Grid>
					<Grid item xs={2} sm={2} lg={2} xl={2}>
						<TextField
							label="CVV"
							value={cccvv}
							onChange={(event) => setCccvv(event.target.value)}
							onBlur={validatePayment('cccvv')}
							fullWidth
							id="credit-card-cvv"
							InputProps={{
								inputComponent: CreditCardCVV,
							}}
						/>
					</Grid>

				</Grid>
				<TextField
					id="credit-card-name"
					label="Name on card"
					value={ccname}
					onChange={(event) => setCcname(event.target.value)}
					onBlur={validatePayment('ccname')}
					fullWidth
					margin="normal"
				/>

				<Button disabled={sending} fullWidth color="secondary" type="submit" variant="contained">{sending ? 'Sending' : 'Make payment'}</Button>

				{ ccerror && <Typography variant="caption" component="div" color="error">{ccerror}</Typography>}
				</form>
			</Box>
		</AccordionDetails>
	</Accordion>
  {/* <FormControl >
   <InputLabel htmlFor="pledge">
     Method
   </InputLabel>
   <Select
     value={method}
     onBlur={validatePayment('method')}
     onChange={(event) => setMethod(event.target.value)}
     input={<Input name="payment-method" id="payment-method" />}
   >
     <MenuItem value={'credit-card'}>Credit Card</MenuItem>
		 <MenuItem value={'eftpos'}>Eftpos</MenuItem>
		 <MenuItem value={'cash'}>Cash</MenuItem>
		 <MenuItem value={'pledge'}>Pledge</MenuItem>
   </Select>
 </FormControl> */}

{ method === 'pledge' ?
	null
  : method === 'credit-card' ?
  null

: method === 'cash' ?
	null
: null
}



    { props.debug && <Button onClick={sendCCPayment}>TEST SEND</Button>}
	</Box>
  </>
)
}
export default connect(state => ({auth: state.auth, cart: state.cart, contact: state.contact })) (Payment)
