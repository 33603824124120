import React from 'react'
import Container from '@material-ui/core/Container'
import Login from './Login'
import Process from './Process'

const Admin = (props) => {

  return (
    <Container>
    	{ props.auth.user && props.auth.user.role && props.auth.user.role === 'admin' ? <Process  {...props} /> : <Login {...props} /> }
    </Container>
  )
}

export default Admin
