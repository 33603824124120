import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider';

const subtypes = [
  {
    id: 'super',
    name: 'SUPER',
    cost: 500,
    contents: 'T-shirt, Tote, 4ZZZ Pin, Bumper Sticker, Your Name On The 4ZZZ Wall!',
    gold: true,
    goldprize: '',
    daily: false,
    dailyprize: '',
    pack: true,
    tshirt: true,
    tshirtsize: '',
    special: true,
    nameoncard: '',
    favshow: ''
  },
  {
    id: 'passionate',
    name: 'Passionate',
    cost: 140,
    contents: 'T-shirt, Tote, Bumper Sticker',
    gold: true,
    goldprize: '',
    daily: false,
    dailyprize: '',
    tshirt: true,
    tshirtsize: '',
    pack: true,
    special: false,
    nameoncard: '',
    favshow: ''
  },
  {
    id: 'full',
    name: 'Full',
    cost: 80,
    contents: 'Bumper Sticker',
    tshirt: false,
    gold: true,
    goldprize: '',
    daily: false,
    dailyprize: '',
    pack: false,
    special: false,
    nameoncard: '',
    favshow: ''
  },
  {
    id: 'concession',
    name: 'Concession',
    cost: 40,
    contents: 'Bumper Sticker',
    tshirt: false,
    gold: true,
    goldprize: '',
    daily: false,
    dailyprize: '',
    pack: false,
    special: false,
    nameoncard: '',
    favshow: ''
  },
  {
    id: 'under18',
    name: 'Under 18',
    cost: 25,
    contents: 'Bumper Sticker',
    tshirt: false,
    gold: true,
    goldprize: '',
    daily: false,
    dailyprize: '',
    pack: false,
    special: false,
    nameoncard: '',
    favshow: ''
  },
  {
    id: 'soloartist',
    name: 'Solo Artist',
    cost: 85,
    contents: '2-week live read campaign valued at $400',
    tshirt: false,
    daily: false,
    dailyprize: '',
    gold: true,
    pack: false,
    special: true,
    soloartist: '',
    favshow: ''
  },
  {
    id: 'band',
    name: 'Band',
    cost: 160,
    contents: '2-week live read campaign valued at $400',
    tshirt: false,
    daily: false,
    gold: true,
    pack: false,
    special: true,
    members: ['', '','', ''],
    band: '',
    favshow: ''
  },
  {
    id: 'household',
    name: 'Household',
    cost: 300,
    contents: '4ZZZ Fridge Magnet, Stubby Cooler, Tote, Bumper Sticker',
    tshirt: false,
    daily: false,
    gold: true,
    pack: true,
    special: true,
    members: ['', '','', ''],
    favshow: ''
  },
  {
    id: 'business',
    name: 'Business',
    cost: 160,
    contents: '2-week live read campaign valued at $400',
    tshirt: false,
    daily: false,
    gold: true,
    pack: false,
    special: true,
    business: '',
    favshow: ''
  },
  {
    id: 'pet',
    name: 'Pet',
    cost: 25,
    contents: '',
    tshirt: false,
    daily: false,
    gold: false,
    pack: false,
    special: true,
    pet: '',
    favshow: ''
  }
]



function subtypeInfo(selected){
  let pos = subtypes.map( e => ( e.id )).indexOf(selected)
  let subtype = subtypes[pos]
  return (
    <p>
      {subtype.contents && <span>Pack Contents: <strong>{subtype.contents}</strong><br/></span> }
      {subtype.gold && <span>Eligible for <strong>Gold prize draw</strong><br/></span> }
      {subtype.daily && <span>Eligible for <strong>Daily prize draw</strong><br/></span> }
      {subtype.special && <span><strong>Special {subtype.name} Sub Prize draw</strong><br/></span> }
      Cost: <strong>$ {subtype.cost}</strong>
    </p>
  )
}

class Summary extends React.Component {


  render() {
    const props = this.props
    return (


  <>
  { props.subscriptions.map((item, index) => (
    <div key={index}>
      <Grid container spacing={4} style={{}}>
        <Grid item xs={8} sm={8} lg={8} xl={8}>
          <Typography variant="h5" gutterBottom>{item.name}</Typography>
        </Grid>
        <Grid item xs={4} sm={4} lg={4} xl={4}>
          <Typography variant="h5" gutterBottom align="right">$ {item.cost + (item.postpack && item.postpack === true ? 10 : 0 )} </Typography>
        </Grid>
        <Grid item>
          <div>
          { (item.id === 'full' || item.id === 'concession' || item.id === 'passionate' || item.id === 'super' || item.id === 'under18') && (
          <p>
            <span>Name on card: <strong>{item['nameoncard']}</strong></span>
          </p>
          )}

          { item.id ? subtypeInfo(item.id) : null}

          {item.gold && (
            <p>
              <span>Radiothon Prize Draw: <strong>{item.goldprize}</strong></span>
            </p>
          )}
          {item.daily && (
            <p>
              <span>Daily Prize Draw: <strong>{item.dailyprize}</strong></span>
            </p>
          )}
          {item.tshirt && (
            <p>
              <span>T-Shirt Size: <strong>{item.tshirtsize}</strong></span>
            </p>
          )}
          {item.pack && (
            <p>
              <span>Send Sub Pack: <strong>{item.postpack ? 'Yes' : 'No'}</strong></span>
            </p>
          )}
          { (item.id === 'soloartist' || item.id === 'band' || item.id === 'pet' || item.id === 'business') && (
            <p>
              <span>{item.name + ' Name'}: <strong>{item[item.id]}</strong></span>
            </p>
          )}
          { (item.id === 'household' || item.id === 'band') && (
            <p>

            {item.members && item.members.map( (member, i) => (
              <span>{item.name + ' Member'}:
              <strong>{member}</strong></span>
            ))}
            {
              item.membersjson && JSON.parse(item.membersjson).map( (member, i) => (
                <span>{item.name + ' Member'}:
                <strong>{member}</strong></span>
              ))
            }
            </p>
          )}
        <p>
          <span>Favourite Program: <strong>{item.favshow}</strong></span>
        </p>
        </div>
        </Grid>



        <Divider />
      </Grid>
    </div>
  ))
  }

  { props.donation.amount ? <Grid container spacing={4} style={{}}>
    <Grid item xs={8} sm={8} lg={8} xl={8}>
      <Typography variant="h5" gutterBottom>Donation</Typography>
    </Grid>
    <Grid item xs={4} sm={4} lg={4} xl={4}>
      <Typography variant="h5" gutterBottom align="right">$ {props.donation.amount} </Typography>
    </Grid>
  </Grid>
  : <></>}

  <Grid container spacing={4} className="summary-total">
    <Grid item xs={8} sm={8} lg={8} xl={8}>
      <Typography variant="h5" gutterBottom>TOTAL</Typography>
    </Grid>
    <Grid item xs={4} sm={4} lg={4} xl={4}>
      <Typography variant="h5" gutterBottom align="right">$ { props.total(props.subscriptions, props.donation)} </Typography>
    </Grid>
  </Grid>

  <TextField
         id="comments"
         label="Comments"
         multiline
         rows="4"
         rowsMax="4"
         fullWidth
         value={this.props.contact.comment}
         onChange={this.props.handleChange('contact', 'comment')}
         margin="normal"
       />


  </>
)
}
}

export default Summary
