const initialState = {
		name: '',
		subnumber: '',
		mobile: '',
		email: '',
		dob: null,
		acknowledgement: true,
		address: '',
		suburb: '',
		postcode: '',
		state: '',
		country: {name: "Australia", alpha: "AUS", code: 36},
		prevsub: 0,
		gender: '',
		genderna: '',
		pronounds: ''
	}

const contact = (state = initialState, action) => {
  switch (action.type) {
		case 'REMOVE_CONTACT':
			return initialState
    case 'EDIT_CONTACT':
      return {
        ...state, [action.key]: action.value
      }
		case 'SET_CONTACT':
			return { ...state, ...action.contact}
		case 'ADD_CONTACT':
			return {
				...state, ...action.contact
			}
		case 'SET_ZEDLETTER':
			return {
				...state, zedletter: action.value
			}
		case 'CLEAR_CONTACT':
			return initialState
    default:
      return state
  }
}

export default contact
