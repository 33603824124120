import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box'
// import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Product from './Product'
import { Link, Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
// import merch from '../staticMerch'

const useStyles = makeStyles((theme) => ({
	wrapper: {
		margin: theme.spacing(1),
		height: 'auto',
		[theme.breakpoints.up('xs')]: {
      width: 'calc(100% - ('+ theme.spacing(1) +'px * 2))',
    },
		[theme.breakpoints.up('sm')]: {
			width: 'calc(50% - ('+ theme.spacing(1) +'px * 2))',
			// height: 'calc(50% - ('+ theme.spacing(1) +'px * 2))',
    },
    [theme.breakpoints.up('md')]: {
			width: 'calc(33.333% - ('+ theme.spacing(1) +'px * 2))',
    },
    [theme.breakpoints.up('lg')]: {
			width: 'calc(25% - ('+ theme.spacing(1) +'px * 2))',
    },
	},
}));


function Merch(props) {

	const classes = useStyles();
	let { path } = useRouteMatch();
	let { merch, auth, data } = props;
	let history = useHistory();

	data.merchInventory.forEach(i => { i.inStock = i.params.reduce( (accumulator, currentValue) => accumulator + currentValue.inventory, 0)  } )

	let stock = data.merchInventory.map( i => ({shortcode: i.shortcode, inStock: i.inStock}) )

	let merchStock = [];

	for(let i=0; i<merch.length; i++) {

		merchStock.push({
		...merch[i], 
		...(stock.find( s => s.shortcode === merch[i].id))}
		);
	}


  return (
	<Box>
		<div style={{display: 'flex', flexWrap: 'wrap'}}>
		{
			merchStock.map((i, n) => {
			if ((i.public || ( auth.user && auth.user.role === 'reception' )) && i.inStock > 0) { return (
				<div className={classes.wrapper} key={n} id={i.id} >
				<Link to={'/merch/' + i.id} style={{textDecoration: 'none'}}>
					<Card square={true}>
						<CardMedia image={i.image[0].src} title="" style={{ paddingTop: '100%' }}/>
						<CardContent>
							<Typography variant="subtitle1">{i.name}</Typography>
						</CardContent>
					</Card>
				</Link>
			</div>
		) } else {return null}
			})
		}
		</div>
		<Switch>
			<Route path={`${path}/:id`}>
			<Modal open={true} onClose={() => history.push('/merch') }>
				<Box>
					<Product />
				</Box>
			</Modal>
			</Route>
		</Switch>
	</Box>
  )
}


export default connect( state => ({ merch: state.merch, cart: state.cart, auth: state.auth, data: state.data }) ) (Merch)
