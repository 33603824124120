import React from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { hideDialog } from '../actions'
function SimpleDialog(props) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} onClick={handleClose}>
      <DialogTitle id="radiothon-graphic-dialog">Radiothon is here!</DialogTitle>
			<img alt="Radiothon 2020 graphic by Pheoebe Paradise - illustrated punk girl salutes a queenslander where a roudy bunch of revellers are partying inside, emblazoned with the text 'houseparty'" style={{width: '100%', height: 'auto'}} src={process.env.PUBLIC_URL + '/radiothon2020.jpg'} />
    	</Dialog>
  );
}

const Landing = (props) =>  {

const handleClose = () => {
		props.dispatch(hideDialog())
};

return (
	<Box>
		<Typography variant="h4">Welcome!</Typography>

		<Typography variant="body1" component="div">

			{/* <img src={process.env.PUBLIC_URL + '/radiothon2022.png'} style={{width: '100%'}} alt="Radiothon Banner" />
			<p>Every day is an adventure at 4ZZZ and <span>we’d like to thank all the trailblazers who’ve joined us this Radiothon</span>, where we’ve been celebrating your Adventurous Ears.</p>
			<p>Don’t worry if you’re late to the party, there’s still time to subscribe. We’ve kept a few choice prizes available to help seal the deal; a year of fresh produce from <b>All My Friends</b>, a year of beer from <b>Young Henrys</b> and a <b>Triffid</b> Golden Ticket for you and a friend.
			</p> */}

			<p>Subscribing to a cultural institution like 4ZZZ means you are investing in what you believe in - supporting media for a better world.</p>

			{/* <p>Those who choose to subscribe during April 1-30 could be bountifully rewarded by our coveted, out of this world prizes from 4ZZZ friends Young Henrys, Dendy Cinemas, Jungle Love Festival, Tradition Tattoo, SWOP, All My Friends, 4000 records and more. For more details about the great prizes during April Atonement, <a href="https://4zzz.org.au" target="_blank" rel="noopener noreferrer">head over to our main website.</a></p> */}

			<p>Your subscription comes with a warm inner glow guarantee plus access to an array of goods including a compilation of exclusive live tracks performed on Right Here Right Now, specials deals at our Sub Discount Outlets and the chance to win on-air subscriber giveaways.</p>
		
			<p>There's a few ways to support 4ZZZ:</p>
			<List>
				<ListItem>Subscribe and donate to 4ZZZ</ListItem>
				<ListItem>Purchase merchandise and tickets to our events</ListItem>
				<ListItem>Manage your Zedder account preferences to receive certain correspondence from 4ZZZ such as Zedletter (weekly newsletter) and how you receive Radio Times.</ListItem>
				<ListItem>Update your Zedder account details so that we are sending your orders, emails and other important correspondence to the right place.</ListItem>
				<ListItem>Receive discounts on all 4ZZZ products if you are a 4ZZZ subscriber. Simply add your desired items to the cart and your discount will be applied at the checkout once you login to your Zedder account. You can even get your discount if you are a new subscriber purchasing merch or tickets in the one transaction, just add your subscription and whatever else you want to buy to the cart and the discount will be applied once you create your Zedder account!</ListItem>
				<ListItem>View our Privacy Policy, Shipping & Returns Policy</ListItem>
			</List>

			<p>If you are experiencing any issues with your order or navigating the cart, please contact 4ZZZ reception on reception@4zzz.org.au. </p>

			</Typography>
			<SimpleDialog open={props.data.landingDialog} onClose={handleClose} />
	</Box>
)}
export default connect( state => ({ data: state.data })) (Landing)
