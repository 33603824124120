import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import SentimentSatisfiedAlt from '@material-ui/icons/SentimentSatisfiedAlt';

function Complete(props) {
  const { onClose, open, type } = props;

  return (
    <Dialog onClose={onClose('reset')} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Radiothon {type ? 'Pledge' : 'Subscription'} Form Sent</DialogTitle>
      <DialogContent>
      { type ?
        <div>
          <p>Thank you for your pledge, you have until September 16 to pay your subscription pledge and still be elegible for great Radiothon prizes. Your pledge payment can be made at the station or by phone, but cannot be made online.</p>
          <p>Thank you for supporting 4ZZZ during our Radio Carnival!</p>
          </div>
      :
        <div>
          <p> Your subscription has been filed, and should be processed within the next 24 hours, you should receive your subscriber pack in the mail in the next two weeks or if you nominated to pick it up come down to the station during our reception opening hours.</p>
          <p>Look out for a welcome email which will include your link to the latest Right Here Right Now compilation. If you do not receive any corespondence, please contact 4ZZZ reception</p>
          <p>Thank you for supporting 4ZZZ during our Radio Carnival!</p>
        </div>
      }

      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose('reset')} variant="contained" color="secondary" aria-label="done" >
          Done
          <SentimentSatisfiedAlt/>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Complete
