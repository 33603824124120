import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Contact from './Contact'
import Stepper from '@material-ui/core/Stepper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Step from '@material-ui/core/Step';
import TextField from '@material-ui/core/TextField';
import StepLabel from '@material-ui/core/StepLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import UserLogin from './UserLogin'
import NewAccount from './NewAccount'
import CartSummary from './CartSummary'
import PayPal from './PayPal'
import Payment from './Payment'
import Button from '@material-ui/core/Button';
import SectionDivider from './SectionDivider'
import { clearReceptionOrder } from '../actions'

function SimpleDialog(props) {
  const { onClose, open, auth, completeOrder} = props;

	const [ completedBy, setCompletedBy] = useState('')
  const handleClose = () => {
    onClose();
  };

	const finish = () => {
		fetch('https://support.4zzz.org.au/api/complete-reception-order', {
			method: 'POST',
			body: JSON.stringify({ order_id: completeOrder.order.order_id,  transaction_id: completeOrder.transaction.id, completedBy: completedBy }), // data can be `string` or {object}!
			headers:{
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + auth.token
			}
		})
		.then(res => res.json())
		.then(response => {
			handleClose()
		})
	}
	const fetchInvoce = () => {
		fetch('https://support.4zzz.org.au/api/order-invoice/' + completeOrder.order.order_id, {
			method: 'GET', // or 'PUT'
			headers:{
				'Authorization': 'Bearer ' + auth.token
			}
		})
		.then(response => {
	    if (!response.ok) {
	      throw new Error('Network response was not ok');
	    }
	    return response.blob();
  	})
		.then(myBlob => {
    	let pdf = URL.createObjectURL(myBlob);
			window.open(pdf);
  	})
	}
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle>Payment completed</DialogTitle>
			<TextField
				id="completedby"
				label="Completed By"
				placeholder="Your Name"
				value={completedBy}
				onChange={ (event) => setCompletedBy(event.target.value)}
				margin="normal"
			/>
			<Button onClick={fetchInvoce}>Print Invoice</Button>
			<Button onClick={finish}>Finish!</Button>
  	</Dialog>
  );
}


const ContactSection = (props) => {
	const [value, setValue] = useState(0)
	return (
		<>
			<Tabs
				value={value}
				indicatorColor="primary"
				textColor="primary"
				onChange={(event, newValue) => setValue(newValue)}
				aria-label="disabled tabs example"
			>
				<Tab label="Returning" />
				<Tab label="New" />
			</Tabs>
			<Box width={{ xs: 4/4, md: 3/4, lg: 1/2 }} >
			{ value === 0 && <UserLogin /> }
		  { value === 1 && <NewAccount /> }
			</Box>

		</>
	)
}

function getStepContent(stepIndex, auth) {
  switch (stepIndex) {
    case 0:
			if ( auth.user && auth.user.role === 'reception' )
				return
			else
      	return <ContactSection />;
    case 1:
      return <CartSummary />;
    case 2:
      return <PayPal />;
    default:
      return 'Unknown stepIndex';
  }
}

const Checkout = (props) => {
	const { auth, dispatch } = props;
	const { activeStep } = props.phoneForm;
	const [ completed, setCompleted ] = useState(false)
	const [ completeOrder, setCompleteOrder ] = useState({})
	let history = useHistory();


	const handleClose = () => {
		setCompleted(false)
		dispatch(clearReceptionOrder())
		history.push('/subscriptions')
	};
	const approvedHandler = (resp) => {
		setCompleteOrder(resp)
		setCompleted(true)
	}
	// let activeStep = 1;
	if ( auth.user && auth.user.role === 'reception' ) {
		return (
			<Container>
				<Box flexGrow="1" style={{overflow: 'hidden'}} display="flex" flexDirection="row" flex={1}>

					<Box width={1/3} >
						<Contact context="reception" />
					</Box>

					<Box width={1/3} >
						<SectionDivider>Order Summary</SectionDivider>
						<CartSummary context="reception"	 />
					</Box>

					<Box width={1/3} >
						<SectionDivider>Payment</SectionDivider>
						<Payment approvedHandler={approvedHandler} />
					</Box>

				</Box>
				<SimpleDialog open={completed} completeOrder={completeOrder} onClose={handleClose} auth={auth} />

			</Container>
		)
	}
	else {
		return (
				<Container style={{overflow: 'hidden', display: 'flex', flex: 1}} >
				<Box flexGrow="1" style={{overflow: 'hidden'}} display="flex" flexDirection="column" flex={1}>
					<Box flexGrow="1" style={{overflowY: 'auto', overflowX: 'hidden'}}>
						<div>
							{ getStepContent(activeStep, auth) }
						</div>
					</Box>
					<Box>
						<Stepper activeStep={activeStep} >
							<Step key="contact">
								<StepLabel>Contact Information</StepLabel>
							</Step>
							<Step key="order">
								<StepLabel>Order Summary</StepLabel>
							</Step>
							<Step key="payment">
								<StepLabel>Payment</StepLabel>
							</Step>
						</Stepper>
					</Box>
				</Box>
				</Container>
		)
	}


}

export default connect(state => ({ phoneForm: state.phoneForm, cart: state.cart, auth: state.auth })) (Checkout)
