const order = ( state = { }, action ) => {
	switch (action.type) {
		case 'ORDER_DETAILS':
			return { ...state, ...action.order }
		default:
			return state
	}
}

export default order
