import React, { useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { addDonation } from '../actions'

const useStyles = makeStyles((theme) => ({
  formControl: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
    minWidth: '50%',
  },
  donationButton: {
    marginRight: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
	submitButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: '50%',
		}
	}
}));

const Donations = props => {
	const { dispatch } = props
	const [donation, setDonation] = useState(0);
	const classes = useStyles();

	return (
		<Container>
			<Typography variant="h5">
				Make a tax deductable donation to 4ZZZ
			</Typography>

			<p>
				Make a tax deductible donation of any amount you choose to 4ZZZ and support community radio. Thanks for your generosity Zedhead!
			</p>

			<p>
				4ZZZ is listed on the Register of Cultural Organisations so donations of $2.00 or more are tax deductible.
			</p>

			<p>
				If you are writing or reviewing your will and considering making a bequest to 4ZZZ, <Link href="https://4zzz.org.au/support" target="_blank">see our website</Link> for sample text to include or email admin@4zzz.org.au for more information.
			</p>

      <p>Select one of the suggested amounts below or enter your own.</p>

      <div>
        <Button className={classes.donationButton} size="large" onClick={() => setDonation(5)} variant="contained">
          $5
        </Button>
        <Button className={classes.donationButton} size="large" onClick={() => setDonation(50)} variant="contained">
          $50
        </Button>
        <Button className={classes.donationButton} size="large" onClick={() => setDonation(500)} variant="contained">
          $500
        </Button>
      </div>

			<div>
				<FormControl className={classes.formControl} variant="outlined">
					<InputLabel htmlFor="outlined-adornment-amount">Donation Amount</InputLabel>
					<OutlinedInput
						id="outlined-adornment-amount"
						value={donation}
						type="number"
						onChange={e => setDonation(e.target.value)}
						startAdornment={<InputAdornment position="start">$</InputAdornment>}
						labelWidth={140}
					/>
				</FormControl>
			</div>
			<div>
				<Button className={classes.submitButton} type="submit" size="large" variant="contained" color="secondary" onClick={ (event) => { dispatch(addDonation(Number(donation) )) } } aria-label="Add">Add to cart</Button>
			</div>

		</Container>
	)
}

export default connect() (Donations)
