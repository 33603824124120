import React from 'react'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { setSection } from '../actions'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import StorefrontIcon from '@material-ui/icons/Storefront';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';

function SubIcon(props) {
  return (
    <SvgIcon {...props}>
			<path
		     d="M 20,4 H 4 C 2.89,4 2.01,4.89 2.01,6 L 2,18 c 0,1.11 0.89,2 2,2 h 16 c 1.11,0 2,-0.89 2,-2 V 6 C 22,4.89 21.11,4 20,4 Z m 0,14 H 4 V 6 h 16 z" />
		  <path
		     d="M 12,14.34 11.42,13.812 C 9.36,11.944 8,10.712 8,9.2 8,7.968 8.968,7 10.2,7 10.896,7 11.564,7.324 12,7.836 12.436,7.324 13.104,7 13.8,7 15.032,7 16,7.968 16,9.2 c 0,1.512 -1.36,2.744 -3.42,4.616 z"
		     id="path919" />
		  <path
		     d="m 7,15 h 10 v 1.5 H 7 Z" />
    </SvgIcon>
  );
}
function DonateIcon(props) {
  return (
    <SvgIcon {...props}>
			<path d="M0 0h24v24H0z" fill="none"/><path d="m7.5 3c-3.08 0-5.5 2.42-5.5 5.5 0 3.78 3.4008 6.8593 8.5508 11.529l1.4492 1.3203 1.4492-1.3105c5.15-4.68 8.5508-7.7591 8.5508-11.539 0-3.08-2.42-5.5-5.5-5.5-1.74 0-3.41 0.80984-4.5 2.0898-1.09-1.28-2.76-2.0898-4.5-2.0898zm4.668 3c0.50448 0 0.92969 0.34902 0.92969 0.76172v0.63477c0.71792 0.1746 1.3403 0.58727 1.709 1.127 0.27164 0.36508 0.1162 0.82661-0.33008 1.0488-0.44627 0.20635-1.0096 0.07797-1.2812-0.28711-0.17463-0.25397-0.48472-0.42894-0.83398-0.47656-0.34926-0.047619-0.71859 0.03218-0.99023 0.22266-0.27165 0.19048-0.42565 0.46013-0.40625 0.76172 0 0.28571 0.15414 0.55562 0.42578 0.74609l2.4453 1.6348c0.71792 0.47619 1.1447 1.1912 1.1641 1.9531 0 0.77778-0.40695 1.4923-1.1055 1.9844-0.32986 0.2381-0.71936 0.3967-1.1074 0.50781v0.61914c0 0.42857-0.4252 0.76172-0.92969 0.76172-0.52389 0-0.93164-0.33315-0.93164-0.76172v-0.61914c-0.75673-0.19048-1.3972-0.6024-1.8047-1.1738-0.25224-0.36508-0.096665-0.82661 0.34961-1.0488 0.44627-0.20635 1.0075-0.079922 1.2598 0.28516 0.19403 0.26984 0.52392 0.46214 0.89258 0.50976 0.38806 0.047619 0.75778-0.031932 1.0488-0.23828 0.29105-0.19048 0.46484-0.49309 0.46484-0.81055-0.019403-0.30159-0.17392-0.60249-0.48438-0.79297l-2.4258-1.6348c-0.71792-0.46032-1.125-1.1602-1.125-1.9063-0.019403-0.74603 0.36789-1.4594 1.0664-1.9355 0.31045-0.22222 0.68029-0.38108 1.0684-0.49219v-0.61914c0-0.4127 0.40775-0.76172 0.93164-0.76172z"/>
		</SvgIcon>
	);
}

const BottomNav = (props) => {

	const [value, setValue] = React.useState(0);
	const { dispatch } = props
	let history = useHistory();

	return (
		<React.Fragment>
		<BottomNavigation
			showLabels
			value={value}
			onChange={(event, newValue) => {
			  setValue(newValue);
				dispatch(setSection(newValue));
				history.push('/' + newValue)
			}}

		>
			<BottomNavigationAction label="Subscriptions" icon={<SubIcon />} value="subscriptions"/>
			<BottomNavigationAction label="Donations" icon={<DonateIcon />} value="donations" />
			<BottomNavigationAction label="Merch" value="merch" icon={<StorefrontIcon />} />
			{/* <BottomNavigationAction label="Tickets" value="tickets" icon={ <ConfirmationNumberIcon /> }/> */}
		</BottomNavigation>

		</React.Fragment>

	)
}

export default connect() (BottomNav)
