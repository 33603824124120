import staticMerch from '../staticMerch'

const merch = (state = staticMerch, action) => {
  switch (action.type) {
    case 'GET_MERCH':
      return [
        ...staticMerch,
      ]
    default:
      return state
  }
}

export default merch
