import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { toggleZedletter, snack } from '../actions'
import InputAdornment from '@material-ui/core/InputAdornment';
import Email from '@material-ui/icons/Email';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import validate from 'validate.js'
import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles((theme) => ({
  giant: {
		fontSize: theme.typography.fontSize * 2,
		border: '4px solid',
		borderRadius: '8px',
		'&:hover': {
			border: '4px solid',
		}
	}
}));

const Zedletter = (props) => {

	const classes = useStyles();
	const { contact, dispatch } = props
	const [ email, setEmail ] = useState('')
  const [ error, setError ] = useState('')
	const [ zedletterUnsubscribe, setZedletterUnsubscribe ] = useState(true)

	const { id } = useParams();

  if ( id ) {
    fetch('https://support.4zzz.org.au/api/zedletter-unsubscribe?' + new URLSearchParams( { id: id } ), {
			method: 'GET', // or 'PUT'
			headers:{
				'Content-Type': 'application/json',
			}
		}).then ( res => {
      if(res.status !== 200) {
				// throw new Error(res.status)
			} else {
        return res.json();
      }
    }).then (
      response => {
        console.log(response);
        setZedletterUnsubscribe(response.zedletter);
      }
    )
  }
	const signupHandler = (event) => {
		event.preventDefault()
		let invalid = validate( { email: email }, { email: { presence: { allowEmpty: false }, email: true } } )
		if (invalid) {
			setError( invalid.email.reduce( (i, v) => i + v + ' ', '') )
			return
		} else {
			setError( null )
		}
		fetch('https://support.4zzz.org.au/api/zedletter-signup', {
			method: 'POST', // or 'PUT'
			body: JSON.stringify( { email: email } ), // data can be `string` or {object}!
			headers:{
				'Content-Type': 'application/json',
			}
		}).then( res => {
			if(res.status !== 200) {
				throw new Error(res.status)
			} else {
				return res.json()
			}
		}).then( ( response ) =>  {
			if (response.error) {
				dispatch(snack('warning', response.error))
			}
			else {
				dispatch(snack('success', 'Zedletter is sent out each Thursday'))
				setEmail('')
			}
		}).catch(function(error)
		{
			dispatch(snack('error', 'Could not reach server'))
			if (error.message === '401') {
			}
			console.log(error)
		});
}

  const clickUnsubscribeHandler = () => {
    fetch('https://support.4zzz.org.au/api/zedletter-unsubscribe', {
			method: 'POST', // or 'PUT'
			body: JSON.stringify( { id: id } ), // data can be `string` or {object}!
			headers:{
				'Content-Type': 'application/json',
			}
		}).then( res => {
			if(res.status !== 200) {
				throw new Error(res.status)
			} else {
				return res.json()
			}
		}).then( ( response ) =>  {
			if (response.error) {
				dispatch(snack('warning', response.error))
			}
			else {
        setZedletterUnsubscribe(response.zedletter);
        if (response.zedletter) {
          dispatch(snack('success', 'You have been subscribed to Zedletter'))
        } else {
          dispatch(snack('success', 'You have been unsubscribed from Zedletter'))
        }
				// setEmail('')
			}
		}).catch(function(error)
		{
			dispatch(snack('error', 'Could not reach server'))
			if (error.message === '401') {
			}
			console.log(error)
		});
  }

	const clickHandler = () => {
		dispatch(toggleZedletter())
	}
	return (
		<Container>
			{ contact.id ?
				<>
				{ contact.zedletter ?
					<p><Typography>Currently receiving Zedletter</Typography>
					<Button className={classes.giant} onClick={clickHandler} variant="outlined" color="secondary">
						Unsubscribe
					</Button></p>
					 :
					<p><Typography>Not receiving Zedletter</Typography>
					<Button className={classes.giant} onClick={clickHandler} variant="outlined" color="primary">
					  Subscribe
					</Button></p>
				}
				</>
			:
        id ? <>
				{ zedletterUnsubscribe ?
					<p><Typography>Currently receiving Zedletter</Typography>
					<Button className={classes.giant} onClick={clickUnsubscribeHandler} variant="outlined" color="secondary">
						Unsubscribe
					</Button></p>
					 :
					<p><Typography>Not receiving Zedletter</Typography>
					<Button className={classes.giant} onClick={clickUnsubscribeHandler} variant="outlined" color="primary">
					  Subscribe
					</Button></p>
				}
				</>
        :
				<>
				<Typography variant="caption">
					Anyone can signup to Zedletter, but if you are already a Zedder you can manage your Zedletter preference once you <Link component={RouterLink} to="/login">login</Link>.
				</Typography>
				<form onSubmit={signupHandler}>
				<TextField
					fullWidth
					error={error}
					helperText={error ? error : ''}
					variant="outlined"
					margin="normal"
					name="name"
					id="name"
					value={email}
					onChange={(event) => setEmail(event.target.value)}
					label="Email"
					InputProps={{
            startAdornment: <InputAdornment position="start"><Email /></InputAdornment>,
						autoCorrect: 'off', autoCapitalize: 'none'
          }}
				/>
				<Button type="submit" variant="contained" size="large">Signup</Button>
				</form>
				</>
			}
		</Container>
	)
}

export default connect(state => ({ auth: state.auth, contact: state.contact })) (Zedletter)
