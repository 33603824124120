import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import { validatePassword, editPassword } from '../actions'

const Password = (props) => {
	const { context, callback, dispatch, passwords } = props
	// const [password, setPassword] = useState('')
	// const [confirmPassword, setConfirmPassword] = useState('')

	useEffect(() => {
		if (passwords.confirmPassword !== '' && passwords.password !== '' && passwords.confirmPassword === passwords.password) {
			console.log('they the same, do it.')
			if (typeof callback === "function" )
				callback(passwords.password)
		}
	}, [passwords, callback])

	const handleChange = (area, type) => event => {
		console.log( area, type, event.target.value)
		dispatch(editPassword(type, event.target.value))
	}

	const validateHandler = (elem) => event => {
		dispatch(validatePassword(event.target.id, event.target.value, context))
	}

	return (
	<Box>
		{ context !== "recover" && context !== "new" &&
		<>
			<div style={{marginTop: '2em'}} >
				<Typography
					color="textSecondary"
					display="block"
					variant="caption"
				>
					Create a password
				</Typography>
			</div>
			<Divider component="div" />
		</>
		}
		<Grid container spacing={2}>
			<Grid item sm={6}>
				<TextField
					name="new-password"
					autoComplete="new-password"
					id="password"
					label={ context === 'recover' ? 'New Password' : 'Password' }
					type="password"
					error={(props.error && props.error.password ) ? true : false}
					helperText={props.error && props.error.password ? props.error.password : '' }
					variant={ context === 'recover' ? 'outlined' : 'standard'}
					value={passwords.password}
					onChange={handleChange('contact', 'password')}
					onBlur={validateHandler('password')}
					fullWidth
					margin="normal"
				/>
			</Grid>
			<Grid item sm={6}>
				<TextField
					name="new-password"
					autoComplete="new-password"
					id="confirmPassword"
					label="Confirm Password"
					type="password"
					error={(props.error && props.error.confirmPassword ) ? true : false}
					helperText={props.error && props.error.confirmPassword ? props.error.confirmPassword : '' }
					variant={ context === 'recover' ? 'outlined' : 'standard'}
					value={passwords.confirmPassword}
					onChange={handleChange('contact', 'confirmPassword')}
					onBlur={validateHandler('confirmPassword')}
					fullWidth
					margin="normal"
				/>
			</Grid>
		</Grid>
	</Box>
	)
}

export default connect(state => ({ passwords: state.passwords, error: state.error })) (Password)
