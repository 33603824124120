import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import merch from './merch'
import tickets from './tickets'
import cart from './cart'
import contact from './contact'
import auth from './auth'
import phoneForm from './phoneForm'
import validate from './validate'
import passwords from './passwords'
import order from './order'
import data from './data'
import donations from './donations'
import subscriptions from './subscriptions'
import error from './error'
import radioTimes from './radioTimes'

const cartPersistConfig = {
  key: 'cart',
  storage: storage,
  blacklist: ['activeSub', 'subDiscount']
}

export default combineReducers({
	merch,
	tickets,
	data,
	order,
  validate,
	phoneForm,
	cart: persistReducer(cartPersistConfig, cart),
	error,
  auth,
	contact,
	donations,
	passwords,
	subscriptions,
	radioTimes
})
