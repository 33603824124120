const staticTickets = [
	{
		id: '45-years-of-the-zeds',
		name: '45 years of the Zeds',
		image: [ { src: process.env.PUBLIC_URL + '/45-years-of-the-zeds.png', caption: ''} ],
		eventDateTime: '2020-12-12T13:00:00',
		eventVenue: 'Club Greenslopes',
		description: `45 years of the Zeds, Club Greenslopes, Sat Dec 12 2020.`,
		body: `<p>Come down to <a href="https://www.clubgreenslopes.com/" target="_blank">Club Greenslopes</a> from 1pm on Saturday, December 12 to celebrate 45 years of 4ZZZ with your fellow Zedders throughout the years.</p>
    <p>If you've ever worked at 4ZZZ as staff or vollie, supported the station with your business or you've been a friend in community radio at some point since 1975, you're invited to this special celebration for the Zed community.</p>
    </p>It will be a relaxed, COVID-safe afternoon to celebrate 4.5 decades of the greatest radio station on earth. Your ticket cost covers food, birthday cake and as many games of lawn bowls as you can handle with great company.</p>
    <p>Tickets are $10.21, grab one now to secure a spot. Please enter the names of all ticket holders below, partners and kids are welcome. Kids under 18 are free and do not need tickets (just bring them along on the day).</p>
    <p>Please consider making a donation at the checkout to keep us fighting the good fight for decades to come. See you there!</p>
		<p>Club Greenslopes is a wheelchair accessible venue, for more details about the venue please consult their website <a href="https://www.clubgreenslopes.com/" target="_blank">clubgreenslopes.com</a> </p>`,
		price: [ 10.21, 10.21 ],
		weight: { base: 0},
		tax: true,
		public: false,
		maxQuantity: 4,
	},
	{
		id: 'announcer-training-full',
		name: 'Announcer Training - Full',
		image: [ { src: process.env.PUBLIC_URL + '/studio-announcers.jpg', caption: ''} ],
		eventDateTime: '2021-01-01T00:00:00',
		eventVenue: '4ZZZ Studios',
		description: `This fee covers your participation in Modules 1 to 4 of 4ZZZ's announcer training.You need to complete payment before you can attend your sessions.`,
		body: `<div><p>This fee covers your participation in Modules 1 to 4 of 4ZZZ's announcer training.You need to complete payment before you can attend your sessions.</p>
		<p><em>*NOTE* The event date listed is not relevant to your session, see correspondence from trainers for correct times and locations.</em></p>
		</div>`,
		price: [ 95, 180 ],
		weight: { base: 0},
		tax: true,
		public: false,
		maxQuantity: 4,
	},
	{
		id: 'announcer-training-concession',
		name: 'Announcer Training - Concession',
		image: [ { src: process.env.PUBLIC_URL + '/studio-announcers.jpg', caption: ''} ],
		eventDateTime: '2021-01-01T00:00:00',
		eventVenue: '4ZZZ Studios',
		description: `This fee covers your participation in Modules 1 to 4 of 4ZZZ's announcer training.You need to complete payment before you can attend your sessions.`,
		body: `<div><p>This fee covers your participation in Modules 1 to 4 of 4ZZZ's announcer training.You need to complete payment before you can attend your sessions.</p>
		<p><em>*NOTE* The event date listed is not relevant to your session, see correspondence from trainers for correct times and locations.</em></p>
		</div>`,
		price: [ 45, 90 ],
		weight: { base: 0},
		tax: true,
		public: false,
		maxQuantity: 4,
	},
	{
		id: 'podcast-training',
		name: 'Podcast Training',
		image: [ { src: process.env.PUBLIC_URL + '/podcast-training.jpg', caption: ''} ],
		eventDateTime: '2022-11-28T00:00:00',
		eventVenue: '4ZZZ Studios',
		description: `Podcast Production Course - Run by Nic Huntington and Luci McAfee.`,
		body: `<p>Podcast Production Course - Run by Nic Huntington and Luci McAfee.</p>`,
		price: [ 20, 25 ],
		weight: { base: 0},
		tax: true,
		public: false,
		maxQuantity: 4,
	},
]

export default staticTickets
