const radioTimes = (state = { expiry: '', mail_radio_times: null, name: '', subnumber: '', address: { address: '', country: '', locality: '', region: '', postcode: ''}}, action) => {
  switch (action.type) {
		case 'CLEAR_AUTH':
			return { success: null }
		case 'REJECT_AUTH':
			return { success: false}
		case 'INIT_RT':
			return { ...action.response }
		case 'EDIT_RT_CONTACT': {
			return { ...state, address: { ...state.address, [action.key]: action.value }}
		}
		case 'RT_FORMAT':
			return { ...state, mail_radio_times: action.value}
    default:
			return state
  }
}


export default radioTimes
