import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink, useParams } from "react-router-dom";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Email from '@material-ui/icons/Email';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Passwords from './Passwords'
import { snack, clearAuth, rejectAuth } from '../actions'

const RecoverPassword = (props) => {

	let { token } = useParams();
	const { dispatch } = props
	const [ recoveryStatus, setRecoveryStatus] = useState('')
	const [ loginName, setLoginName ] = useState('')
	const [ newPassword, setNewPassword] = useState('')
	const [ updateStatus, setUpdateStatus] = useState('')
	// let token = ''

  // componentDidUpdate() {
  //   this.user = JSON.parse( localStorage.getItem('user') )
  // }

  function handleSubmit(event) {
    // console.log('Login: ', this.state.login);
		dispatch(clearAuth())

		if (token) {
			fetch('https://support.4zzz.org.au/api/recover-password', {
				method: 'POST', // or 'PUT'
				body: JSON.stringify({ token: token, name: loginName, newPassword: newPassword }), // data can be `string` or {object}!
				headers:{
					'Content-Type': 'application/json'
				}
			}).then( res => {
				if(res.status !== 200) {
					throw new Error(res.status)
				} else {
					return res.json()
				}
			}).then( ( response ) =>  {
				setUpdateStatus(response.status)
				dispatch(snack(response.status, response.message))
	      // this.props.authHandler('set', response.token, response.user)
	    }).catch(function(error)
	    {
				if (error.message === '401' || error.message === '422') {
					dispatch(rejectAuth())
				}
				console.log(error)
	      ///if status code 401...
	    });
			event.preventDefault();
			return
		}

		else {
			fetch('https://support.4zzz.org.au/api/recover-password', {
				method: 'POST', // or 'PUT'
				body: JSON.stringify({ name: loginName }), // data can be `string` or {object}!
				headers:{
					'Content-Type': 'application/json'
				}
			}).then( res => {
				if(res.status !== 200) {
					throw new Error(res.status)
				} else {
					return res.json()
				}
			}).then( ( response ) =>  {
				if(response.sentEmail) {
					setRecoveryStatus('email-success')
				} else {
					setRecoveryStatus('email-error')
				}
				// this.props.authHandler('set', response.token, response.user)
			}).catch(function(error)
			{
				if (error.message === '401') {
					dispatch(rejectAuth())
					setRecoveryStatus('email-rejected')
				}
				console.log(error)
				///if status code 401...
			});

			event.preventDefault();
			return
		}

  }
	//
  // function handleTestSubmit(event) {
  //   console.log('Login: ', { name: loginName, pass: loginPass });
  //   fetch('https://support.4zzz.org.au/api/test', {
  //     method: 'GET', // or 'PUT'
  //     headers:{
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + localStorage.getItem('token')
  //     }
  //   }).then(res=>res.json()).then(response =>  console.log(response))
  //   event.preventDefault();
  // }


  // handleInputChange(event) {
  //   const target = event.target;
  //   const value = target.value;
  //   const name = target.name;
	//
	//
  //   this.setState({ login: { ...this.state.login, [name]: value } });
  // }



  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel htmlFor="user">Email</InputLabel>
            <OutlinedInput
              name="name"
              id="name"
              value={loginName}
              onChange={(event) => setLoginName(event.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              }
							labelWidth={44}
            />
          </FormControl>


					{ token &&
						<Passwords context="recover" callback={(password) =>   setNewPassword(password) } />
					}

					{ recoveryStatus === 'email-success' &&
						<div>
							An email has been sent to this address, please follow the instructions in the email to reset your password.
						</div>
					}

					{ recoveryStatus === 'email-error' &&
						<div>
							We had an issue sending to your email address, please try again later or contact reception to complete your request.
						</div>
					}

					{ recoveryStatus === 'email-rejected' &&
						<div>
							The email address could not be found in our records, if you think this is an error, please contact reception.
						</div>
					}

					{ updateStatus === 'success' && <div>
						Password has been updated. <Link component={RouterLink} to="/login">Proceed to login page.</Link>
					</div>}


        </CardContent>

        <CardActions>

					<Button type="submit" value="Submit" variant="contained">
        	  { token ? 'Set new password' : 'Recover Password' }
        	</Button>

        </CardActions>
      </form>

    </Box>
  )
}

export default connect( state => ({ auth: state.auth })) (RecoverPassword)
