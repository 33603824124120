import React from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider';

const SectionDivider = (props) => (
		<div style={{marginTop: '2.5em', marginBottom: 0}} >
			<Typography
				style={{ fontWeight: 800, textTransform: 'uppercase'}}
				color="primary"
				display="block"
				variant="caption"
			>
				{props.children}
			</Typography>
			<Divider component="div"/>

		</div>

)

export default SectionDivider 
