import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import NavBar from './components/NavBar'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import BottomNav from './components/BottomNav'
import Body from './components/Body'
import Admin from './components/Admin'
import UserLogin from './components/UserLogin'
import RecoverPassword from './components/RecoverPassword'
import Merch from './components/Merch'
import Cart from './components/Cart'
import Checkout from './components/Checkout'
import Landing from './components/Landing'
import Donations from './components/Donations'
import Subs from './components/Subs'
import Subscription from './components/Subscription'
import PaymentSuccess from './components/PaymentSuccess'
import Snacks from './components/Snacks'
import Product from './components/Product'
import Tickets from './components/Tickets'
import Ticket from './components/Ticket'
import Zedletter from './components/Zedletter'
import RadioTimes from './components/RadioTimes'
import PrivacyPolicy from './components/PrivacyPolicy'
import ShippingReturnsPolicy from './components/ShippingReturnsPolicy'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './print.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Div100vh from 'react-div-100vh'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// const theme = createMuiTheme({
//   palette: {
// 		type: 'dark',
//     primary: {
// 			main: '#fb6129',
//     },
//     secondary: {
// 			main: '#ff4151',
//     },
// 		background: {
// 			default: '#2b0b0e',
// 			paper: '#402123',
// 		}
//   },
// })


const theme = createMuiTheme({
  palette: {
		type: 'dark',
    primary: {
			main: '#fb6129',
    },
    secondary: {
			main: '#ff4151',
    },
		background: {
			default: '#1e1e1e',
			paper: '#363636',
		}
  },
})
class App extends Component {
  constructor(){

    super()

    this.state = {
      token: localStorage.getItem('token'),
      user: JSON.parse(localStorage.getItem('user')),
			debug: true
    }
    this.authHandler = this.authHandler.bind(this);
    this.onUnload = this.onUnload.bind(this);

  }

  onUnload(event) {
    if (!this.state.debug) event.returnValue = `Are you sure you want to leave?`;
  }
  componentDidMount() {
    window.addEventListener("beforeunload", this.onUnload)

  }
  authHandler(action, token, user) {
    if(action === 'set') {
      localStorage.setItem('token', token)
      localStorage.setItem('user', JSON.stringify(user))
      this.setState({token: token})
      this.setState({user: user})
    }
    if(action === 'remove') {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      this.setState({token: ''})
      this.setState({user: {} })
    }
    console.log('oi what')
  }

  render() {
    return (
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router>
				<ThemeProvider theme={theme}>
				<CssBaseline />
				<Div100vh style={{ minHeight: '100rvh', maxHeight: '100rvh', display: 'flex', flexDirection: 'column'}}>
	        <NavBar />
					<Snacks />
					<Switch>
						<Route exact path="/checkout" render={(props) => <Checkout />} />
						<Route exact path="/privacy-policy"><PrivacyPolicy /></Route>
						<Route exact path="/shipping-returns-policy"><ShippingReturnsPolicy /></Route>
						<Route path='/radio-times/:token' render={(props) => <RadioTimes /> } />
            <Route path='/zedletter/:id' render={(props) => <><Box flexGrow="1" style={{overflowY: 'auto', overflowX: 'hidden'}}><Zedletter /></Box><Box>
              <BottomNav />
            </Box></> } />

						<Route>
							<Container style={{display: 'flex', flex: 1, overflowY: 'hidden', overflowX: 'hidden', paddingTop: 20}} flex={1} >
								<Box width={{ xs: 4/4, md: 2/3, lg: 8/12 }} display="flex" flexDirection="column">
									<Box flexGrow="1" style={{overflowY: 'auto', overflowX: 'hidden'}}>
										<Switch>
											<Route path="/" exact render={(props) => <Landing /> } />
							        <Route path="/phoneform" exact render={(props) => <Body {...props} authHandler={this.authHandler} auth={this.state} />} />
											<Route path="/payment-success" exact render={(props) => <PaymentSuccess {...props} />} />
											<Route path="/cart" exact render={(props) => <Cart {...props} authHandler={this.authHandler} auth={this.state} />} />
											<Route path="/zedadmin" render={(props) => <Admin {...props} authHandler={this.authHandler} auth={this.state} />} />
											<Route path="/login" render={(props) => <UserLogin {...props} authHandler={this.authHandler} auth={this.state} />} />
											<Route path="/recover-password" exact render={(props) => <RecoverPassword {...props} authHandler={this.authHandler} auth={this.state} />} />
											<Route path="/recover-password/:token" render={(props) => <RecoverPassword {...props} authHandler={this.authHandler} auth={this.state} />} />
											<Route path="/account" render={(props) => <UserLogin {...props} />} />
											<Route path="/donations" render={(props) => <Donations />}  />
											<Route path="/subscriptions" exact render={(props) => <Subs />}  />
											<Route path='/subscriptions/:id' render={(props) => <Subscription /> } />
											<Route path="/zedletter" exact render={(props) => <Zedletter />} />
							        <Route path="/merch" exact render={(props) => <Merch {...props} authHandler={this.authHandler} auth={this.state} />} />
											<Route path='/merch/:id' render={(props) => <Product /> } />
											<Route path="/tickets" exact render={(props) => <Tickets />}  />
											<Route path="/tickets/:id" render={(props) => <Ticket />}  />
										</Switch>
									</Box>
									<Box>
										<BottomNav />
									</Box>
								</Box>
								<Box width={{md: 1/3, lg: 4/12 }} display={{ xs: 'none', md: 'flex' }} flexDirection="column">
									<Cart />
								</Box>
							</Container>
						</Route>
					</Switch>


				</Div100vh>
				</ThemeProvider>
      </Router>
		</MuiPickersUtilsProvider>
    )
  }
}

export default App
