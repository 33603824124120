import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Subscription from './Subscription'
import { useParams } from "react-router-dom";
import { fetchAndAddSubscription, getShows } from '../actions';


function SubtypeInfo(props){
	const { selected, subscriptions } = props
	const { subtypes } = subscriptions
  let pos = subtypes.map( e => ( e.id )).indexOf(selected)
  let subtype = subtypes[pos]
  return (
    <p>
      {subtype.contents && <span>Pack Contents: <strong>{subtype.contents}</strong><br/></span> }
      {subtype.gold && <span>Eligible for <strong>Gold prize draw</strong><br/></span> }
      {subtype.daily && <span>Eligible for <strong>Daily prize draw</strong><br/></span> }
      {subtype.special && <span><strong>Special {subtype.name} Sub Prize draw</strong><br/></span> }
      Cost: <strong>$ {subtype.cost}</strong>
    </p>
  )
}


const Subscriptions = (props) => {

		let { id } = useParams();
		const [ currentSub, setCurrentSub ] = useState('')
		const { dispatch, subscriptions } = props
		const { subtypes, shows } = subscriptions

		useEffect(() => {
			if(shows.length === 0) {
				dispatch(getShows())
			}
		})

		if(id) {
			setCurrentSub(id)
		}

    return(
    <>
      { props.cart.length === 0 &&
      <Grid container>

        <Grid item xs={10} sm={10} lg={10} xl={10}>
          <FormControl fullWidth>
            <InputLabel htmlFor="prevsub">Subscription Type</InputLabel>
            <Select
              value={currentSub.subtype}
              onChange={(event) => { setCurrentSub(event.target.value)} }
              inputProps={{
                name: 'subtype',
                id: 'subtype',
              }}
            >
              <MenuItem value=''></MenuItem>
              { subtypes.map( (item, index) => ( <MenuItem key={index} value={item.id}>{item.name}</MenuItem> ))}
            </Select>
          </FormControl>
        </Grid>
				{ currentSub.subtype && <SubtypeInfo subscriptions={props.subscriptions} selected={currentSub.subtype} /> }


        <Grid item xs={2} sm={2} lg={2} xl={2}>
          <Fab variant="extended" disabled={(currentSub.subtype === '')} color="secondary" aria-label="Add" onClick={() => dispatch(fetchAndAddSubscription(currentSub))}>
            <AddIcon />
            Add
          </Fab>
        </Grid>

      </Grid>
    }

    {
      props.subscriptions.subscriptions.map((item, index) => (
          <div key={index}>
            <Subscription initState={item} initIndex={index} />
          </div>
      ))
    }
    { props.cart.length !== 0 &&
      <Grid container spacing={2}>
      <Grid item>

        <Typography variant="h5">Add another subscription</Typography>
      </Grid>
      <Grid item xs={10} sm={10} lg={10} xl={10}>

        <FormControl fullWidth>
          <InputLabel htmlFor="prevsub">Subscription Type</InputLabel>
          <Select
            value={currentSub.subtype}
            onChange={props.handleSelectChange('currentSub')}
            inputProps={{
              name: 'subtype',
              id: 'subtype',
            }}
          >
            <MenuItem value=''></MenuItem>
            { subtypes.map( (item, index) => ( <MenuItem key={index} value={item.id}>{item.name}</MenuItem> ))}
          </Select>
        </FormControl>

      </Grid>
      <Grid item xs={2} sm={2} lg={2} xl={2}>
        <Fab variant="extended" disabled={(currentSub.subtype === '')} color="secondary" aria-label="Add" onClick={props.addSubscription(currentSub.subtype)}>
          <AddIcon />
          Add
        </Fab>
      </Grid>

    </Grid>
    }


    <Grid container justify="flex-start"  spacing={2} style={{marginTop: '2em'}}>
    <Button disabled={(props.subscriptions.length === 0)} color="primary" aria-label="Add" onClick={props.completeSubscription}>
      Continue
      <ArrowDownwardIcon />
    </Button>
    {props.debug && <Button onClick={props.sendSubs(props.subscriptions, props.contact)}>TEST SEND</Button>}
    </Grid>
    </>
  )
}

export default connect( state => ({ subscriptions: state.subscriptions,  cart: state.cart })) (Subscriptions)
