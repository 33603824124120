import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import appReducer from './reducers'

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STATE') {
		storage.removeItem('persist:root')
		storage.removeItem('persist:cart')
    state = undefined
  }

  return appReducer(state, action)
}

const loggerMiddleware = []

if (process.env.NODE_ENV !== `production`) {
  loggerMiddleware.push(createLogger())
}

const persistConfig = {
  key: 'root',
  storage: storage,
	whitelist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default (preloadedState) => {
 let store = createStore(
	 persistedReducer,
	 preloadedState,
	 applyMiddleware(
		 thunk,
		 ...loggerMiddleware
	 )
 )
 let persistor = persistStore(store)
 return { store, persistor }
}

// export default function configureStore(preloadedState) {
//   return
// }
