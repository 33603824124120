import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import scriptLoader from 'react-async-script-loader'
import Typography from '@material-ui/core/Typography'
import { snack, setCompleted, setActiveStep, emailInvoice } from '../actions'

const CLIENT = {
	sandbox:
		"AV3RsOJcY1B5PqTQDvGd98J718Ke6U3pjKEV2BgNdHbIjhQMarH7U5qAl5cZUbKmPa2CjLBYluS-sm3G",
	production:
		"AUPqL-y0aiVjPt6gWBygHyGhZd7iUbLgFXHGkqcCXiMqGcfGVQ7LR6e2q6lKb3IMqu7P8HuJzkj45cup"
};

const CLIENT_ID =
	process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;

const localityAbreviator = (locality) => {
	switch (locality) {
		case 'Queensland':
			return 'QLD'
		case 'New South Wales':
			return 'NSW'
		case 'Australian Capital Teritory':
			return 'ACT'
		case 'Victoria':
			return 'VIC'
		case 'South Australia':
			return 'SA'
		case 'Western Australia':
			return 'WA'
		case 'Northern Territory':
			return 'NT'
		default:
			return locality
	}
}

const PayPalPlaceholder = (props) => (
	<div {...props}>PayPal</div>
)

const PayPal = (props) => {
	const PayPalButton = window.paypal ? window.paypal.Buttons.driver("react", { React, ReactDOM }) : PayPalPlaceholder;
	let history = useHistory();

	const { cart, contact, auth, order, dispatch } = props

	// var inlinestyle = { color: 'black', tagline: 'false', layout: 'horizontal' }
	var ccstyle = { color: 'black', layout: 'vertical' }
	var firstName = contact.name.split(' ').slice(0, -1).join(' ');
	var lastName = contact.name.split(' ').slice(-1).join(' ');

	useEffect(() => {
		return () => {
			dispatch(setCompleted( 0 ))
			dispatch(setActiveStep( 1 ))
		}
	}, [dispatch]);

	// const total = 100;
	const createOrder = (data, actions) => {

		var paypalOrder = {
			purchase_units: [
				{
					description: "Supporting 4ZZZ",
					amount: {
						currency_code: "AUD",
						value: cart.total,
						breakdown: {
							item_total: { currency_code: "AUD", value: cart.subTotal } ,
							shipping: { currency_code: "AUD", value: cart.shipping.price },
						}
					},
					shipping: {
						address: {
							address_line_1: contact.address,
							address_line_2: '',
							admin_area_2: contact.suburb,
							admin_area_1: localityAbreviator(contact.state),
							postal_code: contact.postcode,
							country_code: 'AU',
						}
					},
					items: cart.items.map( i => ({ name: i.name, quantity: 1, unit_amount: { currency_code: "AUD", value: i.itemPrice } }) ),
				}
			],
			payer: {
				email_address: contact.email,
				name: {
					'given_name': firstName,
					'surname': lastName,
				},
				address: {
					address_line_1: contact.address,
					address_line_2: '',
					admin_area_2: contact.suburb,
					admin_area_1: localityAbreviator(contact.state),
					postal_code: contact.postcode,
					country_code: 'AU',
				}
			}
		}

		// console.log(order)
		return fetch('https://support.4zzz.org.au/api/create-paypal-transaction', {
			body: JSON.stringify( {order: order, paypalOrder: paypalOrder} ),
			method: 'post',
			headers: {
				'content-type': 'application/json',
				'Authorization': 'Bearer ' + auth.token
			}
		})
		.then(function(res) {
			return res.json();
		})
		.then(function(data) {
			return data.id; // Use the same key name for order ID on the client and server
		});

	}

		const onApprove = (data, actions) => {
			console.log(data)
			return fetch('https://support.4zzz.org.au/api/capture-paypal-transaction', {
				headers: {
					'content-type': 'application/json',
					'Authorization': 'Bearer ' + auth.token
				},
				method: 'post',
				body: JSON.stringify({
					orderID: data.orderID
				})
			})
			.then(function(res) {
				return res.json();
			})
			.then(function(details) {
				console.log(details)
				dispatch(snack('success', 'Payment received.'))
				dispatch(emailInvoice(details.id))
				history.push('/payment-success')
			})
		}

	return (
		<>
		<Typography variant="caption" component="div">Pay for your order by entering your credit / debit card details OR with your Paypal account using the buttons below. Both payment options are processed by Paypal, however you do not need a Paypal account to checkout using a card. Once you make the payment, allow 10-15 seconds for the confirmation window to appear on your screen. Please note that the payment to 4ZZZ via Paypal will appear as 'Creative Broadcasters Ltd' in your bank statement. If you receive no confirmation, check your email to see if you received a tax invoice or email reception@4zzz.org.au.</Typography>
			<PayPalButton
				style={ccstyle}
				enableStandardCardFields={true}
				createOrder={(data, actions) => createOrder(data, actions)}
				onApprove={(data, actions) => onApprove(data, actions)}
			/>
		</>
	)
}

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=AUD&disable-card=amex,discover,jcb,elo,hiper`) (connect( state => ({ auth: state.auth, contact: state.contact, cart: state.cart, order: state.order }) ) (PayPal))
