import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { snack, fetchContactFromToken, rtFormat,  editRTContact, validateContact } from '../actions'

const SectionDivider = (props) => (
		<div style={{marginTop: '2.5em', marginBottom: 0}} >
			<Typography
				style={{ fontWeight: 800, textTransform: 'uppercase'}}
				color="primary"
				display="block"
				variant="caption"
			>
				{props.children}
			</Typography>
			<Divider component="div"/>

		</div>

)


const RadioTimes = (props) => {

	let { token } = useParams();
	const { dispatch, radioTimes } = props
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [ inputValue, setInputValue ] = useState('')
	const loading = open;


	useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

		if (inputValue === '') {
      // setOptions(value ? [value] : []);
			setOptions([]);
      return undefined;
    }

	  (async () => {
	    const response = await fetch('https://support.4zzz.org.au/api/locality/'+ inputValue);
	    const locality = await response.json();

	    if (active) {
	      setOptions(locality);
	    }
	  })();

	  return () => {
	    active = false;
	  };
  }, [inputValue, loading]);

	// let token = ''
	useEffect(() => {
		if (token) {
			dispatch(fetchContactFromToken(token))
		}
 	}, [dispatch, token]);
  // componentDidUpdate() {
  //   this.user = JSON.parse( localStorage.getItem('user') )
  // }

	const validateHandler = (elem) => event => {
		dispatch(validateContact(event.target.id, event.target.value))
	}

  function handleSubmit(event) {
    // console.log('Login: ', this.state.login);

		if (token) {
			fetch('https://support.4zzz.org.au/api/radio-times', {
				method: 'POST', // or 'PUT'
				body: JSON.stringify({ token: token, update: true, radioTimes: radioTimes }), // data can be `string` or {object}!
				headers:{
					'Content-Type': 'application/json'
				}
			}).then( res => {
				if(res.status !== 200) {
					throw new Error(res.status)
				} else {
					return res.json()
				}
			}).then( ( response ) =>  {
				dispatch(snack('success', 'Radio Times preferences updated!'))
	      // this.props.authHandler('set', response.token, response.user)
	    }).catch(function(error)
	    {
				if (error.message === '401' || error.message === '422') {
					dispatch(snack('error', 'Token is incorrect or expired.'))
				}
				console.log(error)
	      ///if status code 401...
	    });
			event.preventDefault();
			return
		}

  }

	const handleChange = (area, type) => event => {
		dispatch(editRTContact(type, event.target.value))
	}

  return (
    <Container>
      <form onSubmit={handleSubmit}>
				<Typography variant="h5">{radioTimes.name}</Typography>
				<Typography variant="h6">{radioTimes.subnumber}</Typography>

				<SectionDivider>Radio Times</SectionDivider>


				<FormControl component="fieldset" margin="normal">
				  <FormLabel component="legend">Radio Times Format</FormLabel>
				  <RadioGroup aria-label="radio-times-format" name="radio-times-format" value={radioTimes.mail_radio_times} onChange={(event) => { dispatch(rtFormat(event.target.value))}}>
				    <FormControlLabel value={String(false)} control={<Radio />} label="A digital copy will be fine" />
				    <FormControlLabel value={String(true)} control={<Radio />} label="Please mail me a physical copy" />
				  </RadioGroup>
				</FormControl>


				<SectionDivider>Delivery Address</SectionDivider>
			  <TextField
					id="address"
			    name="street-address"
			    label="Address"
			    value={radioTimes.address.address}
			    onChange={handleChange('contact', 'address')}
			    onBlur={validateHandler('address')}
			    error={(props.error && props.error.address) ? true : false}
			    helperText={props.error && props.error.address ? props.error.address : '' }
			    fullWidth
			    margin="normal"
			  />
			  <Grid container spacing={2}>
			    <Grid item xs={12} sm={8} lg={8} xl={8}>
						<Autocomplete
							id="suburb
							"
							freeSolo
							autoComplete
							includeInputInList
							open={open}
							onOpen={() => {
								setOpen(true);
							}}
							onClose={() => {
								setOpen(false);
							}}
							onBlur={(event) => {
								dispatch(editRTContact('locality', event.target.value))
								dispatch(validateContact('suburb', event.target.value))
						 	}}
							onChange={(event, newValue) => {
								if(newValue) {
									dispatch(editRTContact('postcode', newValue.postcode))
									dispatch(editRTContact('locality', newValue.suburb))
									dispatch(editRTContact('region', newValue.fullstate))
								}
								setOptions(newValue ? [newValue, ...options] : options);
					        // setValue(newValue);
					    }}
							options={options}
							getOptionSelected={(option, value) => { console.log(option); return ( option.suburb === value.suburb )} }
					    getOptionLabel={(option) => (typeof option === 'string' ? option : option.suburb)}
							onInputChange={(event, newInputValue) => {
								 setInputValue(newInputValue);
						 	}}
							value={radioTimes.address.locality}

							renderInput={(params) => (
								<TextField error={(props.error && props.error.suburb) ? true : false}
								helperText={props.error && props.error.suburb ? props.error.suburb : '' } {...params} label="Suburb" margin="normal" />
							)}
							renderOption={(option) => {
					        return (
					          <Grid container alignItems="center">
					            <Grid item>
					            </Grid>
					            <Grid item xs>
					              {option.suburb}

					              <Typography variant="body2" color="textSecondary">
					                {option.state} {option.postcode}
					              </Typography>
					            </Grid>
					          </Grid>
					        );
					      }}
						/>

			    </Grid>
			    <Grid item xs={12} sm={4} lg={4} xl={4}>
			    <TextField
			      id="postcode"
						name="postal-code"
			      label="Postcode"
			      value={radioTimes.address.postcode}
			      onChange={handleChange('contact', 'postcode')}
			      onBlur={validateHandler('postcode')}
			      error={(props.error && props.error.postcode) ? true : false}
			      helperText={props.error && props.error.postcode ? props.error.postcode : '' }
			      fullWidth
			      margin="normal"
			    />
			    </Grid>
			  </Grid>

				<TextField
					id="state"
					name="address-level1"
					label="State"
					value={radioTimes.address.region}
					onChange={handleChange('contact', 'region')}
					onBlur={validateHandler('state')}
					error={(props.error && props.error.state) ? true : false}
					helperText={props.error && props.error.state ? props.error.state : '' }
					fullWidth
					margin="normal"
				/>

				{/* <Autocomplete
					id="country"
					name="country-name"
					options={data.countries}
					value={radioTimes.address.country}
					onChange={ (event, country) => { dispatch(editRTContact('country', country))}}
					getOptionLabel={(option) => option.name}
					getOptionSelected={(option, value) => { console.log(option.alpha, value, option.alpha === value); return ( option.alpha === value )} }
					renderInput={(params) => <TextField margin="normal" {...params} fullWidth label="Country" />}
				/> */}

				<Button type="submit" variant="contained" style={{margin: '2em 0 '}}>Update my Radio Times Preference</Button>

      </form>

    </Container>

  )
}

export default connect( state => ({ data: state.data ,radioTimes: state.radioTimes })) (RadioTimes)
