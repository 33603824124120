import cloneDeep from 'lodash/cloneDeep';

const shippingRates = [
	{ weight: 0, price: 0},
	{ weight: 0.15, price: 3.5},
	{ weight: 0.5, price: 8},
	{ weight: 1, price: 11},
	{ weight: 3, price: 14},
	{ weight: 5, price: 16}
]

const calculateShipping = (totalWeight, shippingRates) => {
	return shippingRates.find(i => (totalWeight <= i.weight))
}

const packPrice = (product, param) => {
 if (product.pack && param.pack) {
	 return product.pack[param.pack]
 } else {
	 return product.price
 }
}

const calculateTotals = (items, sub = false, pickup = false) => {
	items.forEach( i => {
			i.itemPrice = sub ? i.price[0] : i.price[1]
		}
	)

	let totalWeight = items.reduce( (a, b) => (
		a + b.netWeight
	), 0)

	if (pickup)
		totalWeight = 0

	let shipping = calculateShipping(totalWeight, shippingRates)

	let subTotal = items.reduce( (a, b) => (
		a + b.itemPrice
	), 0)

	let total = subTotal + shipping.price

	let totalTaxable = items.reduce( (a, b) => (
		a + (b.tax ? b.itemPrice : 0)
	), shipping.price ) / 11

	return { totalWeight: totalWeight, shipping: shipping, subTotal: subTotal.toFixed(2), total: total.toFixed(2), totalTaxable: totalTaxable.toFixed(2)}

}
const initState = { stationPickup: false, subDiscount: false, subTotal: 0, totalWeight: 0, shipping: { price: 0 }, totalTaxable: 0,  total: 0, items: [] }
const cart = (state = initState, action) => {
	let totals = {}
	let items = []
	let subDiscount = false, hasSub

	switch (action.type) {
		case 'DROP_ITEM':
			items = state.items.slice()
			items.splice(action.item, 1)
			hasSub = items.find(i => i.type === 'subscription')
			subDiscount = (hasSub || state.activeSub) ? true : false
			totals = calculateTotals(items, subDiscount)
			return {
				...state,
				...totals,
				subDiscount: subDiscount,
				items: items }
		case 'SUB_DISCOUNT':
			totals = calculateTotals(state.items, action.discount, state.stationPickup )
			return { ...state, ...totals, subDiscount: action.discount }
		case 'ADD_TO_CART':
			let products = []
			for( let i = 0; i < action.quantity; i++) {
				let product = cloneDeep(action.product)
				product.price = packPrice(action.product, action.param)
				product.param = action.param
				product.netWeight = action.weight
				products.push(product)
			}
			items = [ ...state.items, ...products ]
			hasSub = items.find(i => i.type === 'subscription')
			subDiscount = (hasSub || state.activeSub) ? true : false
			totals = calculateTotals(items, subDiscount)
			return {
				...state,
				...totals,
				subDiscount: subDiscount,
				items: items
			}
		case 'SET_ACTIVE_SUB':
			totals = calculateTotals(state.items, action.value)
			return {
				...state, ...totals, activeSub: action.value, subDiscount: action.value
			}
		case 'STATION_PICKUP':
			totals = calculateTotals(state.items, state.subDiscount, action.value )

			return {
				...state, ...totals, stationPickup: action.value
			}
		case 'CLEAR_CART':
			return initState
		default:
			return state
	}
}

export default cart
