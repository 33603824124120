import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import NumberFormat from 'react-number-format';
import {EventEmitter} from '../events.js';

class SimpleDialog extends React.Component {
  constructor() {
    super()
    this.state = {
      contacts: {}
    }

  }

  doIT() {

    console.log("DID IT")
  }
  componentDidMount(){
    const id = this.props.contact.contact_id
    if(this.props.contact.ccnumberhint) {

    fetch('https://support.4zzz.org.au/api/cctest/'+id, {
      method: 'GET', // or 'PUT'
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(res=>res.json()).then(response =>  this.setState({contacts: response }))
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.contact.contact_id !== prevProps.contact.contact_id) {
      const id = this.props.contact.contact_id
      if(this.props.contact.ccnumberhint) {

      fetch('https://support.4zzz.org.au/api/cctest/'+id, {
        method: 'GET', // or 'PUT'
        headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res=>res.json()).then(response =>  this.setState({contacts: response }))
      }
    }
  }
  render () {
    const { onClose, open } = this.props;
    const { contacts } = this.state;

    function handleClose() {
      onClose();
    }

    return (
      <Dialog onClose={handleClose} aria-labelledby="title" open={open}>
        <DialogTitle>CC Details</DialogTitle>
        <DialogContent>
        {contacts.ccname}<br />
        <NumberFormat value={contacts.ccnumber} displayType={'text'} format="#### #### #### ####" />
        <br />
        <NumberFormat value={contacts.ccexpiry} displayType={'text'} format="##/##" />

        </DialogContent>
      </Dialog>
    )
  }
}

class PaymentSummary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleClickOpen = this.handleClickOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    EventEmitter.subscribe('ccClick', (event) => this.handleClickOpen())

  }

  handleClickOpen() {
    if(this.props.payment.ccnumberhint) {
      this.setState( { open: true } );
     }
   }

 handleClose() {
     this.setState( { open: false } );
  }

  render() {
    const {open} = this.state
    const props = this.props

  return (
    <>
        <Grid container spacing={2} style={{ padding: '24px 0', marginBottom: '24px'}}>
          <Grid item onClick={this.handleClickOpen}>
            <Typography variant="h5" gutterBottom>Payment</Typography>
            <p>
              <span>Credit Card: <strong>---- ---- ---- {props.payment.ccnumberhint}</strong></span><br />
              <span>Expiry: <strong><NumberFormat value={props.payment.ccexpiry} displayType={'text'} format="##/##" /></strong></span><br />
            </p>
          </Grid>

          <Divider />
        </Grid>
        <SimpleDialog contact={props.payment} open={open} onClose={this.handleClose} />

    </>
  )}
}
export default PaymentSummary
