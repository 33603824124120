import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Contact from './Contact'
import { validateContactForm } from '../actions'

const useStyles = makeStyles((theme) => ({
	submitButton: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: '50%',
		}
	}
}));

const NewAccount = (props) => {
	const classes = useStyles();
	const { dispatch } = props
	return (
	<Container>
		<form onSubmit={(event) => { event.preventDefault(); let test = dispatch(validateContactForm('new')); console.log(test)  }}>
		 	<Contact context="new" />
			<Button className={classes.submitButton} type="submit" size="large" variant="contained" color="secondary">Add contact</Button>
		</form>
	</Container>
)}

export default connect(state => ({ error: state.error })) (NewAccount)
