import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const PrivacyPolicy = () => (
	<Container>
		<Typography variant="h4">4ZZZ Privacy Policy</Typography>
		<Typography variant="body_1">
			<p>This Privacy Policy describes how your personal information is collected, used, and shared by 4ZZZ.</p>

			<Typography variant="h6">CONTACT US</Typography>
			<p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by any of the following methods:
				<li>e-mail us at reception@4zzz.org.au</li>
				<li>call us on 07 3252 1555</li>
				<li>contact us by mail using the details provided below PO BOX 509, Brisbane, QLD, 4006, Australia</li>
			</p>
			
			<Typography variant="h6">PERSONAL INFORMATION WE COLLECT</Typography>

			<p>You are always allowed to deal with us on an anonymous basis.  However, we sometimes find it necessary to collect certain information from you to help fulfil orders, and ensure we are serving our mission statement. There are a number of ways in which we may collect information from you.</p>

			<p>
				These include:
				<li>application forms;</li>
				<li>email</li>
				<li>our website (including cookies)</li>
				<li>over the phone</li>
			</p>


			<p>In addition, there may also be certain laws which may require us to collect personal information from you.  Where these apply, we will provide you with information about our legal requirements when we collect your personal information.</p>

			<p>When you visit the 4ZZZ website, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the website, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the website, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”</p>

			<p>
				We collect Device Information using the following technologies:

				<li> “Log files” track actions occurring on the website, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.</li>
				<li>We use Google Analytics to help us understand how you use our website--you can read more about how Google uses your Personal Information here:  https://www.google.com/intl/en/policies/privacy/.  You can also opt-out of Google Analytics here:  https://tools.google.com/dlpage/gaoptout.</li>
			</p>

			<p>Additionally when you make a purchase or attempt to make a purchase through 4ZZZ, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers processed through PayPal, you can read their Australian Privacy Policy here: https://www.paypal.com/au/webapps/mpp/ua/privacy-fulll), email address, and phone number.</p>

			<p>We may also ask questions regarding gender and age, this helps us to understand the demography of people interacting with 4ZZZ so that we can review and better execute our mission statement. We refer to this information as “Personal Information.”</p>

			<p>We collect Personal Information, in the following ways -

			<li>When you create a “Zedder” Account to make a purchase online</li>
			<li>When you make a purchase offline</li>
			<li>When you fill in a Volunteer Application form</li>
			<li>When you submit music to 4ZZZ via the Music Submission form</li>
			</p>

			<Typography variant="h6">STORING YOUR INFORMATION</Typography>
			<p>We use servers based in Australia, all of your information will be stored on domestic servers.</p>
			<p>Offline information is stored in secure spaces at the station, and can only be accessed by authorised 4ZZZ personnel.</p>

			<Typography variant="h6">HOW DO WE USE YOUR PERSONAL INFORMATION?</Typography>

		<p>

			We use the Personal Information that we collect:

			<li>Generally to fulfill any orders placed through the website (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).</li>
			<li>Contact you for the purpose for which you have submitted information to 4ZZZ; ie to invite you to a volunteer induction, arrange an interview for music submitted to us</li>
			<li>Screen our orders for potential risk or fraud</li>
			<li>When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services, such as receiving our weekly Zedletter and inviting you to vote in our annual Hot 100 countdown.</li>
			<li>We use anonymised Personal Information in marketing materials to better help our sponsors understand our audience and community, as well as to gain insight into the demography of our volunteers which help shape diversity and inclusion strategies.</li>
			<li>From time to time we may use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our website (for example, by generating analytics about how our customers browse and interact with the site, and to assess the success of our marketing and advertising campaigns).</li>
		</p>

			<Typography variant="h6">HOW DO WE HANDLE SENSITIVE INFORMATION?</Typography>

			<p>
				We will not ask you for information revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, or details of health, disability or sexual activity or orientation, unless:

				<li>you consent to providing that information to us;</li>
				<li>the collection of that information is specifically authorised by law;</li>
				<li>the collection is necessary to lessen a serious or imminent threat to your health or safety or the health or safety of another person; or</li>
				<li>the collection of the information is necessary for the establishment, exercise or defence of a legal claim.</li>
			</p>

			<p>4ZZZ is committed to providing an inclusive workplace and community environment free from harassment of discrimination, you can read more about our internal practices in our Station Policy.</p>

			<Typography variant="h6">SHARING YOUR PERSONAL INFORMATION</Typography>

			<p>We take the collection and storage of your Personal Information seriously, if we share personal information with a third party we will contact you first where practicable.</p>

			<p>We may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>

			<Typography variant="h6">DO NOT TRACK</Typography>
			<p>Please note that we do not alter our website’s data collection and use practices when we see a Do Not Track signal from your browser.</p>

			<Typography variant="h6">YOUR RIGHTS</Typography>
			<p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>

			<p>Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the website), or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>

			<Typography variant="h6">DATA RETENTION</Typography>
			<p>When you submit the forms described in this Privacy Policy, we will maintain your Personal Information for our records unless you ask us via the contact included in this Privacy Policy to delete this information.</p>

			<Typography variant="h6">CHANGES</Typography>
			<p>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
		</Typography>
	</Container>
)

export default PrivacyPolicy
