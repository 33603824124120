import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';


const ContactSummary = (props) => (
  <>
      <Grid container spacing={2} style={{ padding: '24px 0', marginBottom: '24px'}}>
        <Grid item>
          <Typography variant="h5" gutterBottom>Contact</Typography>
          <p>
          <span>Name: <strong>{props.contact.name}</strong></span><br />
          <span>Mobile: <strong>{props.contact.mobile}</strong></span><br />
          <span>Email: <strong>{props.contact.email}</strong></span><br />
          <span>Address: <strong>{props.contact.address}</strong></span><br />
          <span>Suburb: <strong>{props.contact.suburb}</strong></span><br />
          <span>Postcode: <strong>{props.contact.postcode}</strong></span><br />
          <span><strong>{props.contact.prevsub ? 'Returning Sub' : 'New Sub'}</strong></span><br />
          {props.contact.prevsub && <span>Previous Sub # <strong>{props.contact.subnumber}</strong></span>}<br />
          </p>
        </Grid>

        <Divider />
      </Grid>

  </>
)

export default ContactSummary
