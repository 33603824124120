import staticSubtypes from '../staticSubtypes'
import { staticDailyprizes, staticRadiothonPrizes, staticGoldprizes, staticAprilAtonementPrizes, staticMonthlyPrizes } from '../staticPrizes'
const subscriptions = ( state = {
	goldprizes: staticGoldprizes,
	generalprizes: staticRadiothonPrizes,
	dailyprizes: staticDailyprizes,
	aprilAtonementPrizes: staticAprilAtonementPrizes,
	monthlyPrizes: staticMonthlyPrizes,
	subtypes: staticSubtypes,
	currentSub: { subtype: ""},
	subscriptions: [],
	monthly: true,
	radiothon: false,
	aprilAtonement: false,
	shows: []
}, action) => {
  switch (action.type) {
    case 'GET_GOLD_PRIZES':
      return {
				...state,
				goldprizes: staticGoldprizes
      }
		case 'GET_GENERAL_PRIZES':
			return {
				...state,
				generalprizes: staticRadiothonPrizes
			}
		case 'GET_DAILY_PRIZES':
			return {
				...state,
				dailyprizes: staticDailyprizes
			}
		case 'GET_APRIL_ATONEMENT_PRIZES':
			return {
				...state,
				aprilatonement: staticAprilAtonementPrizes
			}
		case 'GET_MONTHLY_PRIZES':
			return {
				...state,
				monthlyPrizes: staticMonthlyPrizes
			}
		case 'GET_SUBTYPES':
			return {
				...state,
				subtypes: staticSubtypes
			}
		case 'ADD_SUBSCRIPTION':
			return {
				...state,
				subscriptions: [...state.subscriptions, action.subscription]
			}
		case 'ADD_SHOWS':
			return {
				...state,
				shows: action.shows
			}
    default:
      return state
  }
}

export default subscriptions
