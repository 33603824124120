import React, { useState, useReducer, useEffect } from 'react'
// import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { addToCart } from '../actions'

const useStyles = makeStyles((theme) => ({
  formControl: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
    minWidth: '50%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
	submitButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: '50%',
		}
	}
}));

function getParamsKeys(params) {
	let paramInit = {};
	params && params.forEach(
		i => { paramInit[i.type] = ''  }
	);
	return paramInit;
}

function Product(props) {
  // The <Route> that rendered this component has a
  // path of `/topics/:topicId`. The `:topicId` portion
  // of the URL indicates a placeholder that we can
  // get from `useParams()`.
	let { id } = useParams();
	let { tickets, data, dispatch } = props;
	const classes = useStyles();

  let pos = tickets.map( e => ( e.id )).indexOf(id)
	const product = tickets[pos];

	let inventory = data.merchInventory.find(item => item.shortcode === id)
	console.log(inventory)

	let paramInit = product.params ? getParamsKeys(product.params) : {}

	const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
	const [ticketHolder, setTicketHolder] = useState([undefined]);
	const [param, setParam] = useState(paramInit);
	const [errors, setErrors] = useReducer((errors, { type, value }) => {
		switch (type) {
			case "add":
				return { ...errors, [value]: true  };
			case "remove":
				return delete errors[value];
			default:
				return
		}
	}, {});

	const packPrice = () => {
	 if (product.pack && param.pack) {
		 return product.pack[param.pack].map( (a, n) => <span key={n}>{a}{n === 0 && ' / ' }</span>)
	 } else {
		 return product.price.map( (a, n) => <span key={n}>{a}{n === 0 && ' / ' }</span>)
	 }
	}
	//
	// useEffect( () => {
	// 	console.log(price)
	// 	product.price[0] = price;
	// 	product.price[1] = price;
	// }, [price])

	useEffect( () => {
		product.params && product.params.forEach(i => {
			if ( param[i.type] !== paramInit[i.type] ) {
				setErrors({ type: 'remove', value: i.type });
			}
			if ( param['pack'] && param['pack'] !== paramInit['pack'] ) {
				console.log(param['pack'])
			}
		})
	}, [param, paramInit, product.params])

	const click = (evt) => {
		evt.preventDefault()
		let error = false
		product.params && product.params.forEach(i => { if ( param[i.type] === paramInit[i.type] ) { setErrors({ type: 'add', value: i.type }); error = true } } )
		if (error)
			return
		product.type = 'tickets'
    for ( var i = 0; i < quantity; i++ ) {
      let localProduct = { ...product }
      if ( param['u18#' + (i+1)] ) {
        localProduct['price'] = [0,0];
      }
      dispatch(addToCart(localProduct, { ticketholder: param['tix#' + (i+1) ] }, 1))
    }
	}

	const getInventory = (type, value) => {
		if (inventory && inventory.params) {
			let iv = inventory.params.find(i => (i.type === type && i.value === value))
			if (iv && iv.inventory) {
				return iv.inventory
			}
			else {
				return 0
			}
		} else {
			return 0
		}
	}
	// useEffect(() => {
	// 	console.log('again',paramInit)
	// 	// setParam(paramInit)
	// 	// setQuantity(1)
	// }, [paramInit])

  return (
		<Box>
		<Grid container spacing={2} >

			<Grid item xs={8} sm={8} lg={8} xl={8}>
				<Typography variant="h5">{product.name}</Typography>
			</Grid>
			<Grid item xs={4} sm={4} lg={4} xl={4}>
				<Typography variant="h5" align="right">
				$	{ packPrice() }
				</Typography>
			</Grid>
			<Grid item xs={12} sm={12} lg={12} xl={12}>
			<img alt="product" src={product.image[0].src} style={{width: '100%', height: 'auto'}} />
			<form onSubmit={click}>
      <h4>{product.eventVenue}</h4>
      <em>{moment(product.eventDateTime).format('LLLL')}</em>
      <div style={{paddingTop: '20px', paddingBottom: '20px'}}>$ { packPrice() }</div>

			<div dangerouslySetInnerHTML={{ __html: product.body }} />
			<div style={{paddingTop: '20px', paddingBottom: '20px'}}>
			{
				product.pack ?
					product.pack instanceof Array ?
						product.pack.map( (a, n) => <span style={{display: 'none'}} key={n}>{a}{n === 0 && ' / ' }</span>)
					:
						Object.keys(product.pack).map((a, n) => <div key={n}><span>{a}: </span>{ product.pack[a].map( (b, n) => <span key={n} className="eh">{b}{n === 0 && ' / ' }</span>)} </div>)
				: null
			}
			</div>

			<div>
			{/* <pre>{JSON.stringify(param)}</pre> */ }
			{ product.params && product.params.map( (p, n) =>

				<FormControl  variant="outlined" className={classes.formControl} key={n}>
					<InputLabel id={p.type + '-label'}>{p.option}</InputLabel>
					<Select
						labelId={p.type + '-label'}
						id={p.type}
						value={param[p.type]}
						error={errors[p.type]}
						onChange={ (event) => setParam( { ...param, [p.type]:  event.target.value } ) }
					>
						{p.values.map( (v, n) => <MenuItem value={v} key={n} disabled={(getInventory(p.type, v) === 0)}>{v} <span style={{ fontStyle: 'italic', marginRight: 30, position: 'absolute', right: 0 }}>{ ( getInventory(p.type, v) === 0) ? 'out of stock' : (getInventory(p.type, v) < 5) ? '< 5 remain' : '' }</span></MenuItem> ) }
					</Select>
					<FormHelperText>{errors[p.type] ? 'Required' : ''}</FormHelperText>
				</FormControl>
				)
			}
			</div>

			{
				product.id === 'misc' &&
				<TextField className={classes.formControl} label="Price" variant="outlined" value={price} type="number" onChange={e => { product.price[0] = Number(e.target.value); product.price[1] = Number(e.target.value); setPrice(e.target.value) }} InputLabelProps={{ shrink: true }} />

			}
			<div>
				<TextField className={classes.formControl} label={  product.maxQuantity ?  'Quantity (max ' +product.maxQuantity + ')' : 'Quantity' } variant="outlined" value={quantity} type="number" onChange={e => { if (e.target.value === '' || ( e.target.value > 0 && e.target.value <= (product.maxQuantity ? product.maxQuantity : 10)  )) { setQuantity(e.target.value); setTicketHolder([ ...Array(Number(e.target.value))]) } }} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: 1, max: product.maxQuantity ? product.maxQuantity : 10 } }} />
			</div>

      <div>
        <Typography variant="caption">Please enter the names of each person you're buying a ticket for, their name will appear on the door.</Typography>
        { ticketHolder.map((e, i) => (
            <div>
              <TextField className={classes.formControl} label={'Ticket Holder #' + (i + 1)} variant="outlined" value={param['tix#'+(i + 1)]} onChange={e => { setParam({ ...param, ['tix#'+(i + 1)]:  e.target.value } ) }}  />

            </div>
          )
        )
        }
      </div>

			<Button className={classes.submitButton} type="submit" dis size="large" variant="contained" color="secondary" disabled={ getInventory('', '') - quantity < 1 } aria-label="Add to cart">{ getInventory('', '') - quantity < 1 ? 'Out of stock' : 'Add to cart' }</Button>
			</form>

			</Grid>
		</Grid>
		</Box>
	)
}

export default connect( state => ({ data: state.data, tickets: state.tickets, cart: state.cart }) ) (Product)
