import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const ShippingReturnsPolicy = () => (
	<Container>
		<Typography variant="h4">Shipping & Returns Policy</Typography>
		<Typography variant="body_1">

			<Typography variant="h6">How To Refund or Exchange An Item</Typography>

			<p>If you receive an item that is in an unsatisfactory condition, please email 4ZZZ Reception within 48 hours of receiving the item on reception@4zzz.org.au. Please include your original order receipt and reason why the item is unsatisfactory, along with pictures where possible. Your item must be in its original unused condition to be exchanged, unless there is a manufacturer defect.</p>

			<p>If your item is deemed to be unsatisfactory by 4ZZZ personnel, you may be eligible for an exchange or full refund of that item. You must return the item within 30 days of your purchase. You may exchange the item for a different size or color of the same item. The cost of shipping for returns is the customer's responsibility.</p>

			<p>
				Mail your returned item to: <br/>
				PO BOX 509 <br/>
				FORTITUDE VALLEY QLD 4006 <br/>
				AUSTRALIA <br/>
			</p>

			<Typography variant="h6">Refunds</Typography>
			<p>We don’t offer refunds for incorrect sizing or if you change your mind about a product, so be careful when making your purchase. We will only offer a refund if the item delivered is in an unsatisfactory condition, as outlined in Section 1.</p>

			<p>We do not refund the original shipping and handling that you paid on the order.</p>

			<Typography variant="h6">Shipping Policy</Typography>

			<p>The rate charged for the shipping of your order is based on the weight of your products and your location. Before the final checkout page you will be shown what the cost of shipping will be, and you will have a chance to not place your order if you decide not to.</p>

			<p>All purchases made between Monday - Friday will be shipped within 48 hours of purchase. Any weekend or public holiday orders will be processed on the next business day. We are a volunteer-run organisation, so please bear with us during busy periods, we will do everything we can to process your order as soon as possible.</p>

			<p>All orders are shipped by Australia Post, and depending on location we advise 7-10 day turnaround for delivery.</p>

			<p>Please note that COVID-19 has disrupted our reception processes, so there may be delays in shipping your items. Please contact reception@4zzz.org.au to inquire as to the status of your order.</p>

			<Typography variant="h6">Tracking Orders</Typography>

			<p>All orders over will be assigned an AusPost tracking number. Once we have shipped your order you will receive an email with the tracking number.</p>

			<Typography variant="h6">Pickup Instructions</Typography>

			<p>You will be offered several windows for order pickup in your order receipt, emailed to you. Please check your email to complete this process. All pick up orders are to be collected from 4ZZZ at 264 Barry Parade, Fortitude Valley 4006.</p>

		</Typography>
	</Container>
)

export default ShippingReturnsPolicy
