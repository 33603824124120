import staticTickets from '../staticTickets'

const tickets = (state = staticTickets, action) => {
  switch (action.type) {
    case 'GET_MERCH':
      return [
        ...staticTickets,
      ]
    default:
      return state
  }
}

export default tickets
