import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Login from './Login'
import Contact from './Contact'
import { validateContactForm } from '../actions'

const useStyles = makeStyles((theme) => ({
	submitButton: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: '50%',
		}
	}
}));

const UserLogin = (props) => {
	const classes = useStyles();
	const {auth, dispatch} = props;
	let history = useHistory();

	useEffect(() => {
		if(auth.user && auth.user.role === 'reception') {
			history.push('/subscriptions')
		}
	}, [auth.user])

  return (
    <Container>
    	{ auth.user ?
				<form onSubmit={(event) => { event.preventDefault(); let test = dispatch(validateContactForm('returning')); console.log(test) }}>
					<Contact />
					<Button className={classes.submitButton} variant="contained" size="large" color="secondary" type="submit" >
						Update and Continue
					</Button>
				</form>
				:
				<Box>
					<Typography variant="caption">
						Have you subscribed in the past? Then you already have an account. Use the same email and password you would have used previously on the old 4ZZZ cart, or hit "Reset Password" and enter the email attached to your last subscription (the email that Zedletter is sent to) to update your password.
					</Typography>
					<Login {...props} />
				</Box>
			}
    </Container>
  )
}

export default connect(state => ({ auth: state.auth })) (UserLogin)
