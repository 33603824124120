import React, { useEffect }  from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import { confirmOrder, stationPickup, subDiscount } from '../actions'

const ColorButton = withStyles((theme) => ({
  root: {
    height: '56px',
		borderRadius: 0
  },
}))(Button);

function CartSummary(props) {
	let { cart, dispatch, context, auth } = props;

	useEffect( () => {
			console.log(context)
			if (context === 'reception') {
				dispatch(stationPickup(true))
			}
	}, [context]);

	return (
		<React.Fragment>
			<Box style={{ padding: '1em 1em'}}>
				<Grid justify="flex-end" container spacing={2}>
				{ 	cart.items.map((c, n) =>
					<React.Fragment key={n}>
						<Grid item xs={10} style={{ display: 'flex' }}>
						<div style={{paddingRight: '10px'}}><img alt="item thumbnail" src={c.image && c.image instanceof Array ? c.image[0].src : c.image } style={{width: '75px', height: 'auto'}} /></div>
						<div><Typography>{c.name}</Typography>
						{c.param && Object.keys(c.param).map(n => <div style={{fontSize: '85%', color: '#ccc'}} key={n}>{c.param[n]}</div> )}</div>
						</Grid>
						<Grid item xs={2}>
						<Typography align="right">
						{c.itemPrice}
						</Typography>

						</Grid>
					</React.Fragment>
				)}
				</Grid>
			</Box>
			<Box style={{ padding: '1em 1em'}}>
				<Grid container justify="flex-end" spacing={2}>
					<Grid item xs={10}>
						<FormLabel style={{lineHeight: '42px'}}>Sub Discount</FormLabel>
					</Grid>
					<Grid item xs={2} style={{paddingRight: 0, display: 'flex', justifyContent: 'flex-end' }}>
						<Checkbox
							disabled={auth.user.role === 'zedder'}
							checked={cart.subDiscount}
							onChange={(event) => dispatch(subDiscount(event.target.checked)) }
							inputProps={{ 'aria-label': 'primary checkbox' }}
				      />
					</Grid>

					<Grid item xs={10}>
						<FormLabel style={{lineHeight: '42px'}}>Pickup from 4ZZZ</FormLabel>
					</Grid>
					<Grid item xs={2} style={{paddingRight: 0, display: 'flex', justifyContent: 'flex-end' }}>
						<Checkbox
							onChange={(event) => dispatch(stationPickup(event.target.checked)) }
							checked={cart.stationPickup}
							inputProps={{ 'aria-label': 'primary checkbox' }}
							/>
					</Grid>

					{ cart.stationPickup ?
						<Grid item xs={12}>
							<Typography variant="caption" component="div">Pickup available only during Reception opening hours at 264 Barry Parade, Fortitude Valley. Open 10am-6pm Monday-Friday, Saturday & Sunday 10am-2pm. Extended hours available during Radiothon and events, see our socials for updates.</Typography>
						</Grid>
						: null
					}

					<Grid item xs={10}>
					<Typography variant="h6">
						Subtotal
					</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant="h6" align="right">
							{cart.subTotal}
						</Typography>
					</Grid>

					{ cart.totalWeight !== 0 &&
					<>
						<Grid item xs={10}>
						<Typography >
							 Shipping Price
						</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography align="right">
								{cart.shipping.price}
							</Typography>
						</Grid>

						<Grid item xs={10}>
						<Typography variant="caption">
							Total Weight {parseFloat(cart.totalWeight).toFixed(2)}kg
						</Typography>
						</Grid>
						<Grid item xs={2}>
							<Typography align="right">
							</Typography>
						</Grid>


					</>
					}


					<Grid item xs={10}>
					<Typography variant="h5">
						Total
					</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography variant="h5" align="right">
							{cart.total}
						</Typography>
					</Grid>

					<Grid item xs={10}>
					<Typography variant="caption">
						{ cart.totalTaxable !== 0 ? `Including ${parseFloat(cart.totalTaxable).toFixed(2)} GST` : `* No GST required` }
					</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography align="right">
						</Typography>
					</Grid>

				</Grid>
			</Box>
			{ context !== 'reception' ? <Box>
				<ColorButton disabled={cart.length === 0} color="secondary" variant="contained" fullWidth={true} size="large" onClick={() => dispatch(confirmOrder()) } >Confirm order</ColorButton>
			</Box> : null
			}

		</React.Fragment>
	)
}

export default connect( state => ({ merch: state.merch, cart: state.cart, auth: state.auth }) ) (CartSummary)
