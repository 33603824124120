const initialState = {
		password: '',
		confirmPassword: '',
	}

const passwords = (state = initialState, action) => {
  switch (action.type) {
		case 'REMOVE_PASSWORD':
			return initialState
    case 'EDIT_PASSWORD':
      return {
        ...state, [action.key]: action.value
      }
		case 'ADD_PASSWORD':
			return {
				...state, ...action.contact
			}
    default:
      return state
  }
}

export default passwords
