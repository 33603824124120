import React from 'react'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid'

const AdminText = (props) => (
  <div id="admin-section">

      <Box m={1} p={1} textAlign="center">
      <Typography variant="h5">ADMIN ONLY</Typography>
      OVER THE PHONE OR IN PERSON PROCESS
    </Box>
    <Grid container alignItems="stretch">
      <Grid item xs={6}>
        <Box border={1} m={1} p={1}>
          <Typography variant="h5">STEP 1: PROCESS PAYMENT</Typography>
           <p>☐ SUCCESSFUL PAYMENT</p>
           <p>☐ PLEDGE</p>
           <p>☐ DECLINED</p>
           Sign When Successful Payment _________________
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box border={1} m={1} p={1}>
          <Typography variant="h5">STEP 2: ENTER SUB DETAILS</Typography>
          <Typography variant="h6">Follow guidelines for database entry</Typography>
          <Box fontStyle="italic">All subs, esp. Pets have their own unique sub number listed under the subscriber’s name (not under pet name)</Box>
          <p>☐    NEW         ☐    EXISTING</p>
          <p>Sub#       ___ ___ ___ ___ ___</p>
          <p>Ex:       ______ / ______ / ______</p>
          <p>(If also a Pet Sub # ___ ___ ___ ___ ___ Ex: __/__/____)<br />
          ☐    NEW         ☐    EXISTING)</p>
          Sign when entered or updated in database ___________
        </Box>
      </Grid>
    </Grid>

    <Box border={1} m={1} p={1}>
      <Typography variant="h5">STEP 3: PRINT RECEIPT</Typography>
      <p>Google Chrome Browsers Only – B&W + turn off 2-sided settings. Check all details are correct before printing</p>
      <p>Sign When Printed________________________</p>
    </Box>

    <Grid container alignItems="stretch">
      <Grid item xs={6}>
    <Box border={1} m={1} p={1}>
      <Typography variant="h5">STEP 4: ISSUE SUB CARD</Typography>
      <Typography variant="h6">(Only to Australian Addresses)</Typography>
         <p>☐ Gold Card – Super Subs</p>
         <p>☐ Red Card – Passionate</p>
         <p>☐ Blue Card – Everyone Else (<b>NOT</b> Pets)</p>
      <Box textAlign="center">
      <Typography variant="h6">PLUS SUBSCRIBER LETTER
      & BUMPER STICKER</Typography>

      <p>
        Standard Letter – U18, Concession, Full, Pass’t,<br />
        Business Letter + up to 2 Cards<br />
        Band: Musician Letter, Music Dep Ltr + up to 4 Cards<br />
        Solo Artist: Artist Letter + Music Dep Ltr (1 card only)<br />
         Household: Household Letter + up to 4 Cards<br />
        Super Sub: Super Sub Letter<br />
      </p>


      <Box fontWeight="fontWeightBold">In Person at Reception ONLY:<br />
      ASK IF THEY HAVE A COPY OF RADIOTIMES<br />
      If No – give them a copy & only one per subscriber
      </Box>
      </Box>


      <p>SIGN WHEN COMPLETED   __________________</p>
    </Box>
    </Grid>
    <Grid item xs={6}>
    <Box border={1} m={1} p={1}>
      <Typography variant="h5">STEP 5: POST or GIVEN TO SUB</Typography>
      <Typography variant="h6">(Only to Australian Addresses)</Typography>

      <p><b>BLUE CARDS:</b> (NO EXTRA CHARGE TO POST) <br />
        SUB CARD & Sub Letter + RECEIPT + Bumper Sticker <br />
        <Box textAlign="right" >Date Posted/Issued _ _ / _ _ / _ _</Box>
      </p>

      <p>
        <b>RED CARDS</b> (PASSIONATE)<br />
        PACK (3 items)<br />
        <b>4ZZZ T-shirt 4ZZZ Tote Bag 4ZZZ Bumper Sticker</b><br />
        SUB CARD & Sub Letter + RECEIPT<br />
        <Box textAlign="right" >Date Posted/Issued _ _ / _ _ / _ _</Box>
       </p>

      <p>
        <b>GOLD CARDS</b> (SUPER SUBS)<br />
        PACK (4 items)<br />
        <b>4ZZZ T-shirt 4ZZZ Tote Bag 4ZZZ Bumper Sticker 4ZZZ Pin</b><br />
        SUB CARD & Sub Letter + RECEIPT<br />
        <Box textAlign="right" >Date Posted/Issued _ _ / _ _ / _ _</Box>
      </p>

      <p>
        <b>Blue CARDS</b> (Household)<br />
        PACK (4 items)<br />
        <b>4ZZZ Tote Bag 4ZZZ Bumper Sticker 4ZZZ Fridge Magnet 4ZZZ Stubby Cooler</b><br />
        SUB CARD & Sub Letter + RECEIPT<br />
        <Box textAlign="right" >Date Posted/Issued _ _ / _ _ / _ _</Box>
      </p>


      <p>  PASSIONATE/ SUPER SUB/ HOUSE HOLD PACK ISSUED</p>
      <p> YES / NO / POST</p>
      <p>  (Passionate/ Super/ Household Subs Only – Circle One)</p>
      <p>  SIGN WHEN COMPLETED   __________________</p>

    </Box>
    </Grid>
  </Grid>
  </div>
)

export default AdminText
