import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box'
// import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Subscription from './Subscription'
import { Link, Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
// import merch from '../staticMerch'

const useStyles = makeStyles((theme) => ({
	wrapper: {
		margin: theme.spacing(1),
		height: 'auto',
		[theme.breakpoints.up('xs')]: {
      width: 'calc(100% - ('+ theme.spacing(1) +'px * 2))',
    },
		[theme.breakpoints.up('sm')]: {
			width: 'calc(50% - ('+ theme.spacing(1) +'px * 2))',
			// height: 'calc(50% - ('+ theme.spacing(1) +'px * 2))',
    },
    [theme.breakpoints.up('md')]: {
			width: 'calc(33.333% - ('+ theme.spacing(1) +'px * 2))',
    },
    [theme.breakpoints.up('lg')]: {
			width: 'calc(25% - ('+ theme.spacing(1) +'px * 2))',
    },
	}
}));

function Subs(props) {

	const classes = useStyles();
	let { path } = useRouteMatch();
	let { subscriptions } = props;
	let history = useHistory();

  return (
	<Box>
		<div style={{display: 'flex', flexWrap: 'wrap'}}>
		{
			subscriptions.subtypes.map((i, n) =>
				<div className={classes.wrapper} key={n} id={n} >
					<Link to={'/subscriptions/' + i.id} style={{textDecoration: 'none'}}>
						<Card square={true}>
							<CardMedia image={i.image} title="" style={{ paddingTop: '60%' }}/>
							<CardContent>
								<Typography variant="subtitle1">{i.name}</Typography>
							</CardContent>
						</Card>
					</Link>
				</div>
			)
		}
		</div>
		<Switch>
			<Route path={`${path}/:id`}>
				<Modal open={true} onClose={() => history.push('/subscriptions') }>
					<Box className={classes.paper}>
						<Subscription />
					</Box>
				</Modal>
			</Route>
		</Switch>

	</Box>
  )
}


export default connect( state => ({ subscriptions: state.subscriptions, cart: state.cart }) ) (Subs)
