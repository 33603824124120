import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import { editContact, validateContact, setContact, setActiveSub } from '../actions'
import Passwords from './Passwords'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DatePicker } from "@material-ui/pickers";
import moment from 'moment';

class SimpleDialog extends React.Component {
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  render() {
    const { classes, onClose, searchResults, ...other } = this.props;
    const results = searchResults.map((item, index) => (
      <div key={index} onClick={() => this.handleListItemClick(item)}>{item.subnumber} {item.firstname} {item.lastname} {item.email} {item.phone} {item.expiry} {item.subtypecode} {item.delivery_address && item.delivery_address.locality}</div>
    ))
    return (
      <Dialog onClose={this.handleClose} aria-labelledby="search-results" {...other}>
        <DialogTitle id="search-results">Search Results</DialogTitle>
        <DialogContent>
          <div>{results}</div>
        </DialogContent>
      </Dialog>
    );
  }
}

const SectionDivider = (props) => (
		<div style={{marginTop: '2.5em', marginBottom: 0}} >
			<Typography
				style={{ fontWeight: 800, textTransform: 'uppercase'}}
				color="primary"
				display="block"
				variant="caption"
			>
				{props.children}
			</Typography>
			<Divider component="div"/>

		</div>

)


const Contact = (props) => {

	const autoComplete = true

	const [ subsOpen, setSubsOpen ] = useState(false)
	const [ searchResults, setSearchResults ] = useState([])
	// const [ selectedValue, setSelectedValue ] = useState({})
	const { context, contact, dispatch, auth, data } = props
  // const [ localContact, setContact ] = useState({})
	const [ inputValue, setInputValue ] = useState('')
	const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

		if (inputValue === '') {
      // setOptions(value ? [value] : []);
			setOptions([]);
      return undefined;
    }

	  (async () => {
	    const response = await fetch('https://support.4zzz.org.au/api/locality/'+ inputValue);
	    const locality = await response.json();

	    if (active) {
	      setOptions(locality);
	    }
	  })();

	  return () => {
	    active = false;
	  };
  }, [inputValue, loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

	// useEffect(() => {
	// 	// let err = validate(contact, constraints)
	// 	// if (!err) {
	// 	//   this.setState({ completed: { ...this.state.completed, 0: true } })
	// 	//   //this.setState({ activeStep: 1 })
	// 	// }
	// }, [localContact])

	const handleClose = value => {
		// setSelectedValue(value)
		setSubsOpen(false)
    if(value) {
			if (value.expiry && moment(value.expiry).isSameOrAfter() ) {
				dispatch(setActiveSub(true))
			}
      dispatch(setContact({ ...contact, id: value.id, name: value.firstname + ' ' + value.lastname, subnumber: value.subnumber, mobile: value.phone, email: value.email, address: value.delivery_address ?  value.delivery_address.address : '', suburb: value.delivery_address ? value.delivery_address.locality : '', state:  value.delivery_address ? value.delivery_address.region : '', postcode:  value.delivery_address ? value.delivery_address.postcode : '', dob: value.dob, gender: value.gender ? value.gender : '', genderna: value.genderna ? value.genderna : '', pronouns: value.pronouns ? value.pronouns : ''  }))
    }
  };

	const handleChange = (area, type) => event => {
		if (type === 'dob') {
			dispatch(editContact(type, event))
			return
		}
		if (type === 'acknowledgement') {
			dispatch(editContact(type, event.target.checked))
			return
		}
		dispatch(editContact(type, event.target.value))
	}


  const searchSub = (search) => event => {
    setSubsOpen(true)

    if (contact.subnumber) {
      fetch('https://support.4zzz.org.au/api/subsuggestno/' + contact.subnumber, {
				headers:{
					'Authorization': 'Bearer ' + auth.token
				}
			}).then( res => res.json() ).then(
				response => setSearchResults(response) ).catch(err => console.log(err))
    } else if (contact.name) {
      let names = contact.name.match(/([\w\s]*) (\w*)$/)
      if (names) {
        fetch('https://support.4zzz.org.au/api/subsuggesta/' + names[2].toUpperCase() + '/' + names[1].toUpperCase(), {
					headers:{
						'Authorization': 'Bearer ' + auth.token
					}
				}).then( res => res.json() ).then( response => setSearchResults( response)).catch(err => console.log(err))
      }
    }
  }

	const validateHandler = (elem) => event => {
		dispatch(validateContact(event.target.id, event.target.value, context))
	}

	return(
  <>
	<SectionDivider>Contact Details</SectionDivider>
  <Grid container spacing={2} alignItems="center" alignContent="space-between">
    <Grid item xs={auth.admin ? 10 : 12} sm={auth.admin ? 10 : 12} lg={auth.admin ? 10 : 12} xl={11}>
		  <TextField
		    id="name"
				name="name"
		    label="Name"
		    value={contact.name}
		    onChange={handleChange('contact', 'name')}
		    onBlur={validateHandler('name')}
		    error={(props.error && props.error.name) ? true: false  }
		    helperText={props.error && props.error.name ? props.error.name : 'The full name of the cardholder as it appears on the credit or debit card.' }
		    fullWidth
		    margin="normal"
		  />
		  <Grid container spacing={2}>
		    <Grid item xs={12} sm={6} lg={6} xl={6}>

		      <FormControl fullWidth margin="normal">
		        <InputLabel htmlFor="prevsub">New or Returning</InputLabel>
		        <Select
							disabled={!(context ===  "new" || context === "reception")}

		          value={contact.prevsub}
		          onChange={handleChange('contact', 'prevsub')}
		          inputProps={{
		            name: 'prevsub',
		            id: 'prevsub',
		          }}
		        >
		          <MenuItem value={0}>New Sub</MenuItem>
		          <MenuItem value={1}>Returning Sub</MenuItem>
		        </Select>
		      </FormControl>
		    </Grid>
		    <Grid item xs={12} sm={6} lg={6} xl={6}>
		      { contact.prevsub ?
		      <div>
		        <FormControl fullWidth margin="normal">
		          <InputLabel htmlFor="subnumber">Sub Number</InputLabel>
		          <Input
		            id="subnumber"
								name="subnumber"
		            value={contact.subnumber}
								disabled={!(context ===  "new" || context === "reception")}
		            onChange={handleChange('contact', 'subnumber')}
		            onBlur={validateHandler}
		            startAdornment={<InputAdornment position="start">#</InputAdornment>}
		          />
		        </FormControl>

		        <SimpleDialog
		          searchResults={searchResults}
		          open={subsOpen}
		          onClose={handleClose}
		        />
		      </div>
		      : null
		      }
		    </Grid>
			</Grid>
  	</Grid>
		{ context === 'reception' &&
		<Grid item xs={1} sm={1} lg={1} xl={1} style={{justifyContent: 'right'}}>
		  <Fab color="secondary" disabled={
		    contact.prevsub !== 1
		      ? true
		      : contact.name.length === 0
		        ? (contact.subnumber
		          ? (contact.subnumber.length === 0
		            ? true
		            : false )
		          :true )
		        : false
		    } aria-label="Search" onClick={ searchSub() }>
		    <SearchIcon />
		  </Fab>
		</Grid>
		}
	</Grid>

  <TextField
		id="email"
    name="email"
    label="Email"
		autoComplete={autoComplete ? 'username' : false}
    value={contact.email}
    onChange={handleChange('contact', 'email')}
    onBlur={validateHandler('email')}
    error={(props.error && props.error.email) ? true : false}
    helperText={props.error && props.error.email ? props.error.email : 'Email is used to login, access subscriber benefits and receive Zedletter' }
    fullWidth
		margin="normal"
  />
	{ context ===  "new" &&
 	<Passwords context={context} />
	}
	<TextField
		id="mobile"
		name="tel"
		label="Mobile"
		value={contact.mobile}
		onChange={handleChange('contact', 'mobile')}
		onBlur={validateHandler('mobile')}
		error={(props.error && props.error.mobile) ? true : false}
		helperText={props.error && props.error.mobile ? props.error.mobile : 'Mobile phone number allows text requests and access to giveaways' }
		fullWidth
		margin="normal"
	/>


	<Box display="flex" flexDirection="row">
	  <DatePicker
	    disableFuture
	    openTo="year"
	    format="dd/MM/yyyy"
	    label="Date of birth"
	    views={["year", "month", "date"]}
	    value={contact.dob}
	    onChange={handleChange('contact', 'dob')}
		 	margin="normal"
	  />

		<FormControl margin="normal" style={{flex: 1,
	    marginLeft: 8}}>
			<InputLabel htmlFor="gender">Gender</InputLabel>
			<Select
				value={contact.gender}
				onChange={handleChange('contact', 'gender')}
				inputProps={{
					name: 'gender',
					id: 'gender',
				}}
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				<MenuItem value={2}>Woman</MenuItem>
				<MenuItem value={1}>Man</MenuItem>
				<MenuItem value={4}>Non-binary</MenuItem>
				<MenuItem value={0}>Not listed</MenuItem>
			</Select>
		</FormControl>
		{ ( contact.gender === 0 ) && <TextField 
			style={{flex: 1, marginLeft: 8}}
			id="genderna"
			name="genderna"
			label="Specify if you like"
			value={contact.genderna}
			onChange={handleChange('contact', 'genderna')}
			fullWidth
			margin="normal"
		/>}
	</Box>

	<FormHelperText>Date of birth and gender are optional, we collect this information to better understand our community.</FormHelperText>

	<TextField 
		id="pronouns"
		name="pronouns"
		label="Pronouns"
		value={contact.pronouns}
		onChange={handleChange('contact', 'pronouns')}
		helperText="If you complete this field your pronouns will appear next to your name on the 4ZZZ textline and in your subscriber record. They can be updated or changed on request."
		fullWidth
		margin="normal"
	/>

	<FormControl margin="normal">
	<FormControlLabel margin="normal"
			 control={<Checkbox checked={contact.acknowledgement} onChange={handleChange('contact', 'acknowledgement')} name="on-air-acknowledgement" />}
			 label="On-air acknowledgement?"
	 />
	 <FormHelperText>Would you like an on-air shoutout for supporting 4ZZZ? (Only your firstname / suburb will be identified, at announcers discretion)</FormHelperText>
	</FormControl>

	<SectionDivider>Address</SectionDivider>
  <TextField
		id="address"
    name="street-address"
    label="Address"
    value={contact.address}
    onChange={handleChange('contact', 'address')}
    onBlur={validateHandler('address')}
    error={(props.error && props.error.address) ? true : false}
    helperText={props.error && props.error.address ? props.error.address : '' }
    fullWidth
    margin="normal"
  />
  <Grid container spacing={2}>
    <Grid item xs={12} sm={8} lg={8} xl={8}>
			<Autocomplete
				id="suburb
				"
				freeSolo
				autoComplete
				includeInputInList
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				onBlur={(event) => {
					dispatch(editContact('suburb', event.target.value))
					dispatch(validateContact('suburb', event.target.value, context))
			 	}}
				onChange={(event, newValue) => {
					if(newValue) {
						dispatch(editContact('postcode', newValue.postcode))
						dispatch(editContact('suburb', newValue.suburb))
						dispatch(editContact('state', newValue.fullstate))
					}
					setOptions(newValue ? [newValue, ...options] : options);
		        // setValue(newValue);
		    }}
				options={options}
				getOptionSelected={(option, value) => { console.log(option); return ( option.suburb === value.suburb )} }
		    getOptionLabel={(option) => (typeof option === 'string' ? option : option.suburb)}
				onInputChange={(event, newInputValue) => {
					 setInputValue(newInputValue);
			 	}}
				value={contact.suburb}

				renderInput={(params) => (
					<TextField error={(props.error && props.error.suburb) ? true : false}
					helperText={props.error && props.error.suburb ? props.error.suburb : '' } {...params} label="Suburb" margin="normal" />
				)}
				renderOption={(option) => {
		        return (
		          <Grid container alignItems="center">
		            <Grid item>
		            </Grid>
		            <Grid item xs>
		              {option.suburb}

		              <Typography variant="body2" color="textSecondary">
		                {option.state} {option.postcode}
		              </Typography>
		            </Grid>
		          </Grid>
		        );
		      }}
			/>
    {/* <TextField
      id="suburb"
			name="address-level2"
      label="Suburb"
      value={contact.suburb}
      onChange={handleChange('contact', 'suburb')}
      onBlur={validateHandler('suburb')}
      error={(props.error && props.error.suburb) ? true : false}
      helperText={props.error && props.error.suburb ? props.error.suburb : '' }
      fullWidth
      margin="normal"
    /> */}
    </Grid>
    <Grid item xs={12} sm={4} lg={4} xl={4}>
    <TextField
      id="postcode"
			name="postal-code"
      label="Postcode"
      value={contact.postcode}
      onChange={handleChange('contact', 'postcode')}
      onBlur={validateHandler('postcode')}
      error={(props.error && props.error.postcode) ? true : false}
      helperText={props.error && props.error.postcode ? props.error.postcode : '' }
      fullWidth
      margin="normal"
    />
    </Grid>
  </Grid>

	<TextField
		id="state"
		name="address-level1"
		label="State"
		value={contact.state}
		onChange={handleChange('contact', 'state')}
		onBlur={validateHandler('state')}
		error={(props.error && props.error.state) ? true : false}
		helperText={props.error && props.error.state ? props.error.state : '' }
		fullWidth
		margin="normal"
	/>

	<Autocomplete
		id="country"
		name="country-name"
		options={data.countries}
		value={contact.country}
		onChange={ (event, country) => { dispatch(editContact('country', country))}}
		getOptionLabel={(option) => option.name}
		getOptionSelected={(option, value) => { return ( option.name === value.name )} }
		renderInput={(params) => <TextField margin="normal" {...params} fullWidth label="Country" />}
	/>


	{/* <TextField
		id="country"
		name="country-name"
		label="Country"
		value={contact.country}
		onChange={handleChange('contact', 'country')}
		onBlur={validateHandler('country')}
		error={(props.error && props.error.country) ? true : false}
		helperText={props.error && props.error.country ? props.error.country : '' }
		fullWidth
		margin="normal"
	/> */}
{/*
	<SectionDivider>Delivery Address</SectionDivider>
	<FormControlLabel
		control={<Checkbox checked={hasBilling} onChange={(event) => setHasBilling(event.target.checked)} color="primary" name="hasBilling" />}
		label="Deliver to a different address"
		margin="normal"
	/>
	{ hasBilling &&
	  <><TextField
			id="address"
	    name="street-address"
	    label="Address"
	    value={contact.deliveryAddress}
	    onChange={handleChange('contact', 'deliveryAddress')}
	    onBlur={validateHandler('deliveryAddress')}
	    error={(props.error && props.error.deliveryAddress) ? true : false}
	    helperText={props.error && props.error.deliveryAddress ? props.error.deliveryAddress : '' }
	    fullWidth
	    margin="normal"
	  />
	  <Grid container spacing={2}>
	    <Grid item xs={12} sm={8} lg={8} xl={8}>
	    <TextField
	      id="suburb"
				name="address-level2"
	      label="Suburb"
	      value={contact.deliverySuburb}
	      onChange={handleChange('contact', 'deliverySuburb')}
	      onBlur={validateHandler('deliverySuburb')}
	      error={(props.error && props.error.deliverySuburb) ? true : false}
	      helperText={props.error && props.error.deliverySuburb ? props.error.suburb : '' }
	      fullWidth
	      margin="normal"
	    />
	    </Grid>
	    <Grid item xs={12} sm={4} lg={4} xl={4}>
	    <TextField
	      id="postcode"
				name="postal-code"
	      label="Postcode"
	      value={contact.postcode}
	      onChange={handleChange('contact', 'deliveryPostcode')}
	      onBlur={validateHandler('deliveryPostcode')}
	      error={(props.error && props.error.deliveryPostcode) ? true : false}
	      helperText={props.error && props.error.deliveryPostcode ? props.error.deliveryPostcode : '' }
	      fullWidth
	      margin="normal"
	    />
	    </Grid>
	  </Grid>
		<TextField
			id="country"
			name="country-name"
			label="Country"
			value={contact.deliveryCountry}
			onChange={handleChange('contact', 'deliveryCountry')}
			onBlur={validateHandler('deliveryCountry')}
			error={(props.error && props.error.deliveryCountry) ? true : false}
			helperText={props.error && props.error.deliveryCountry ? props.error.deliveryCountry : '' }
			fullWidth
			margin="normal"
		/></>
		} */}
  </>
)}

export default connect(state => ({ data: state.data, contact: state.contact, error: state.error, auth: state.auth})) (Contact)
