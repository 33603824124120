import validate from 'validate.js'

var passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

validate.validators.myAsyncValidator = (value) => {
  return new validate.Promise( (resolve, reject) => {
		fetch('https://support.4zzz.org.au/api/check-email', {
			method: 'POST', // or 'PUT'
			body: JSON.stringify({ email: value }), // data can be `string` or {object}!
			headers:{
				'Content-Type': 'application/json'
			}
		})
		.then(response => response.json())
		.then( json => {
			if(json.emailExists) {
				resolve("already exists, you can reset your password via the Login page");
			}
			else {
				resolve();
			}
		})
  });
};

const contactConstraintsNew = {
  name: { presence: {allowEmpty: false} },
  mobile: { presence: {allowEmpty: false} },
  email: { presence: {allowEmpty: false}, email: true, myAsyncValidator: true  },
  address: { presence: {allowEmpty: false} },
	suburb: { presence: {allowEmpty: false} },
	state: { presence: {allowEmpty: false} },
  country: { presence: {allowEmpty: false} },
  postcode: { presence: {allowEmpty: false} }
}

const passwordConstraints = {
	password: { format: { pattern: passwordPattern, message: 'must contain 8 characters, a number and a mix of upper and lower case letters.'} },
	confirmPassword: { presence: {allowEmpty: false}, equality: "password" }
}

const contactConstraintsReturning = {
  name: { presence: {allowEmpty: false} },
  mobile: { presence: {allowEmpty: false} },
  email: { presence: {allowEmpty: false}, email: true },
	suburb: { presence: {allowEmpty: false} },
	state: { presence: {allowEmpty: false} },
  address: { presence: {allowEmpty: false} },
	country: { presence: {allowEmpty: false} },
  postcode: { presence: {allowEmpty: false} }
}

const donationConstraints = {
  amount: { presence: {allowEmpty: false} }
}

const paymentConstraints = {
  ccname: { presence: {allowEmpty: false} },
  ccnumber: { presence: {allowEmpty: false} },
  ccexpiry: { presence: {allowEmpty: false} },
}

export {contactConstraintsNew, contactConstraintsReturning, passwordConstraints, donationConstraints, paymentConstraints};
