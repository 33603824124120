import React from 'react'
import Summary from './Summary'
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import FaceIcon from '@material-ui/icons/Face';
import DeleteForever from '@material-ui/icons/DeleteForever';
import RefreshIcon from '@material-ui/icons/Refresh';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PrintIcon from '@material-ui/icons/Print';
import ContactSummary from './ContactSummary'
import AdminText from './AdminText'
import PaymentSummary from './PaymentSummary'
import {EventEmitter} from '../events.js';

class Process extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
      selected: {
        contact: {},
        donation: {},
        subscripions: [],
        payment: {}
      }

    }
    this.getPending()
    this.getPending = this.getPending.bind(this)
    this.processSub = this.processSub.bind(this)
    this.getCC = this.getCC.bind(this)

  }


  getCC() {
    EventEmitter.dispatch('ccClick', {what: 'oh yeah'})
  }

  getPending() {
    fetch('https://support.4zzz.org.au/api/unpaid', {
      method: 'GET', // or 'PUT'
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }).then(res=>res.json()).then(response =>  this.setState({contacts: response }))
  }

  processSub(id) {
    if(window.confirm('Send to database?')){
      console.log(id)
      console.log("DOOOOO IT")
      fetch('https://support.4zzz.org.au/api/process', {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(id),
        headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res=>res.json())
      .catch(error => console.error('Error:', error))
      .then(response =>  this.postProcess(response) )
    }
  }
  deleteSub(id) {
    if(window.confirm('PERMANENTLY DELETE RECORD?')){
      console.log(id)
      console.log("DOOOOO IT")
      fetch('https://support.4zzz.org.au/api/delete', {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(id),
        headers:{
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res=>res.json())
      .catch(error => console.error('Error:', error))
      .then(response =>  this.postDeleteProcess(response) )
    }
  }

  postProcess(response) {
    window.alert("Record added with subid: " + response.subid)
    this.getPending()
  }

  postDeleteProcess(response) {
    window.alert("Record deleted")
    this.getPending()
  }

  componentDidMount() {
  }



  test(contact){
    console.log('clicked')
    console.log(contact)
    let donation = contact.donations && contact.donations.amount ? { amount: contact.donations.amount } : {amount : 0}
    this.setState({selected: { contact: contact, donation: donation, subscriptions: contact.subscriptions, payment: contact.payment } })
    //this.setState({selected: contact})
  }

  render() {
    function total(subs, donation){
    //  debugger
      const totals = subs.map( e => (
        e.cost + (e && e.postpack === true ? 10 : 0 ) )
      )
      const sums = totals.reduce((a,b) => a + b, 0 )
      const allTotal = sums + (donation && donation.amount ? donation.amount : 0 )
      return allTotal
    }

    return (

      <><article>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="stretch"
          spacing={4}
        >
        <Grid className="unpaid-subs" item xs={4}>
          <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
            <Grid container justify="space-between">
              <Grid item>
                Subscriptions for processing
              </Grid>
              <Grid item className="noprint">
                <IconButton onClick={this.getPending}><RefreshIcon /></IconButton>
              </Grid>
            </Grid>
            </ListSubheader>
          }
        >
          {
            this.state.contacts.map((contact, id) => (
              <>
              <ListItem key={id} button onClick={() => {this.test(contact)}}>
                <ListItemAvatar>
                  <Avatar>
                    { contact.payment.ccexpiry ?
                      <CreditCardIcon />
                      : <FaceIcon />

                    }

                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={contact.name} />
              </ListItem>
              <Divider variant="inset" component="li" />
              </>
            )
            )
          }
          </List>
          </Grid>

          <Grid item xs>
          { this.state.selected && this.state.selected.contact.name &&
            <div>
              <List subheader={
                <ListSubheader disableGutters component="div" id="nested-list-subheader">
                  <Grid container justify="space-between">
                    <Grid item>
                      Created: {this.state.selected.contact.created_at}
                    </Grid>
                    <Grid item className="noprint">
                      <IconButton><PrintIcon onClick={ () => {window.print()}} /></IconButton>
                      <IconButton><CreditCardIcon onClick={this.getCC} /></IconButton>
                      <IconButton><AssignmentIcon onClick={ () => {this.processSub(this.state.selected.contact)} }/></IconButton>
                      <IconButton color="secondary"><DeleteForever onClick={ () => {this.deleteSub(this.state.selected.contact)} }/></IconButton>
                    </Grid>
                  </Grid>
                </ListSubheader>
              }>
              <div>
                  <ContactSummary contact={this.state.selected.contact} />
                  <Summary subscriptions={this.state.selected.subscriptions}
                    donation={this.state.selected.donation} total={total}
                  />
                  <PaymentSummary payment={this.state.selected.payment} />
                  </div>
              </List>
            </div>
          }
          </Grid>

        </Grid>
      </article>
      <div><AdminText /></div>
      </>

    )
  }
}

export default Process
