import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './configureStore'
import { getCountries, getMerchInventory } from './actions/'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

const {store, persistor} = configureStore()

ReactDOM.render(
	<React.Fragment>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	 </React.Fragment>,
	 document.getElementById('root'));
// registerServiceWorker();
serviceWorker.unregister();

store.dispatch(getCountries())
store.dispatch(getMerchInventory())
