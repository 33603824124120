const auth = (state = {}, action) => {
  switch (action.type) {
		case 'CLEAR_AUTH':
			return { success: null }
		case 'REJECT_AUTH':
			return { success: false}
		case 'SET_AUTH':
			return { ...state, success: true, token: action.token, user: action.user, admin: action.admin }
    default:
			return state
  }
}


export default auth
