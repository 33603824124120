const initialState = { snack: {}, validity: { contact: false, passwords: false }}
const error = (state = initialState, action) => {
  switch (action.type) {
		case 'SET_PASSWORD_ERROR':
			return { ...state, [action.id]: action.error }
		case 'UNSET_PASSWORD_ERROR':
			delete state[action.id]
			return { ...state, [action.id]: action.error }
		case 'SET_SUB_ERROR':
			return { ...state, [action.id]: action.error }
		case 'UNSET_SUB_ERROR':
			delete state[action.id]
			return { ...state, [action.id]: action.error }
		case 'CLEAR_SUB_ERRORS':
			return initialState
		case 'FORM_VALIDITY':
			return {...state, validity: { ...state.validity, [action.id]: action.value }}
		case 'SNACK':
			return { ...state, snack: { status: action.status, message: action.message  } }
    default:
			return state
  }
}


export default error
