const EUCountries = [ 'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB', 'GF', 'GP', 'MQ', 'ME', 'YT', 'RE', 'MF', 'GI', 'AX', 'PM', 'GL', 'BL', 'SX', 'AW', 'CW', 'WF', 'PF', 'NC', 'TF', 'AI', 'BM', 'IO', 'VG', 'KY', 'FK', 'MS', 'PN', 'SH', 'GS', 'TC', 'AD', 'LI', 'MC', 'SM', 'VA', 'JE', 'GG', 'GI', 'CH', ];

const initialState = {
		countries: [], merchInventory: [], EUCountries: EUCountries, landingDialog: false
	}



const data = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_COUNTRIES':
      return {
        ...state, countries: action.countries
      }
		case 'ADD_MERCH_INVENTORY':
			return {
				...state, merchInventory: action.merch
			}
		case 'HIDE_DIALOG':
			return {
				...state, landingDialog: false
			}
    default:
      return state
  }
}

export default data
