import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment';
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

//import Card from '@material-ui/core/Card';
//import CardContent from '@material-ui/core/CardContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import validate from 'validate.js'
//import Checkbox from '@material-ui/core/Checkbox';
//import DialogContentText from '@material-ui/core/DialogContentText';
import creditCardType, { types as CardType } from 'credit-card-type';
import Donation from './Donation'
import Contact from './Contact'
import Subscriptions from './Subscriptions'
import Summary from './Summary'
import Payment from './Payment'
import Complete from './Complete'

let suggestions = [
];







const constraints = {
  name: { presence: {allowEmpty: false} },
  mobile: { presence: {allowEmpty: false} },
  email: { presence: {allowEmpty: false}, email: true },
  address: { presence: {allowEmpty: false} },
  suburb: { presence: {allowEmpty: false} },
  postcode: { presence: {allowEmpty: false} }
}

const donationConstraints = {
  amount: { presence: {allowEmpty: false} }
}

const paymentConstraints = {
  ccname: { presence: {allowEmpty: false} },
  ccnumber: { presence: {allowEmpty: false} },
  ccexpiry: { presence: {allowEmpty: false} },
}

const initialState =  {
  sendStatus: false,
  activeStep: 0,
  completed: { 0: false, 1: false, 2: false, 3: false },
  contact: {
    name: '',
    prevsub: '',
    subnumber: '',
    mobile: '',
    email: '',
    address: '',
    suburb: '',
    postcode: ''
  },
  subscriptions: [

  ],
  currentSub: {
    subtype: ''
  },
  donation: {
    amount: null
  },
  payment: {
    pledge: false,
    ccnumber: '',
    ccexpiry: '',
    ccname: '',
  },
  open: false,
  selectedValue: '',
  searchResults: [],

  completeOpen: false,
  completeSetOpen: ''
}

class Body extends React.Component {
  constructor() {
    super()
    this.debug = false
    this.token = ''
    this.state = initialState
    this.handleComplete = this.handleComplete.bind(this)
    this.validateSub = this.validateSub.bind(this)
    this.completeForm = this.completeForm.bind(this)
    // this.validateCC = this.validateCC.bind(this)
    this.completeSubscription = this.completeSubscription.bind(this)
    this.goldPrize = this.goldPrize.bind(this)
    this.tShirtSize = this.tShirtSize.bind(this)
    this.postSubPack = this.postSubPack.bind(this)
    this.removeSubscription = this.removeSubscription.bind(this)
    this.addSubscription = this.addSubscription.bind(this)
    this.addMembers = this.addMembers.bind(this)
  }

  handleStep = step => () => {
    this.setState({
      activeStep: step,
    });
  };

  validateCC = (section, prop) => event => {
    console.log('does it happen?')
    console.log(section);
    console.log(prop);
    console.log(event.target.value)
    console.log(creditCardType(event.target.value))
    console.log();
    let cctype = creditCardType(event.target.value).filter(function(card) {
      return card.type === CardType.MASTERCARD || card.type === CardType.VISA;
    })
    if(!cctype.length){
      this.setState({ error: { ...this.state.error, 'ccnumber': 'Valid VISA or MASTERCARD only.' }})
    }
    else {
      this.setState({ error: { ...this.state.error, 'ccnumber': false }})

    }

  }

  validatePayment = () => event => {
    if(event.target.name && event.target.name === 'pledge' && event.target.value === true) {
      this.setState({ completed: { ...this.state.completed, 4: true } })
    }
    else {
      console.log('when')
      console.log(event)
      console.log(event.target.id)
      console.log( event.target.value)
      let err1 = validate({[event.target.id]: event.target.value}, paymentConstraints)
      console.log(err1)
      let err = validate(this.state.payment, paymentConstraints)
      if (!err) {
        this.setState({ completed: { ...this.state.completed, 4: true } })
        //this.setState({ activeStep: 3 })
      }
      else {
        this.setState({ error: { ...this.state.error, payment: err1[event.target.id] }})
      }
    }


  }

  validateDonation = () => event => {
    console.log('when')
    console.log(event)
    console.log(event.target.id)
    console.log( event.target.value)
    let err1 = validate({[event.target.id]: event.target.value}, donationConstraints)
    console.log(err1)
    let err = validate(this.state.donation, donationConstraints)
    if (!err) {
      this.setState({ completed: { ...this.state.completed, 2: true } })
      this.setState({ activeStep: 3 })
    }
    else {
      this.setState({ error: { ...this.state.error, donation: err1[event.target.id] }})
    }
  }

  validateSub = (elem) => event => {
    // console.log(elem)
    let err1 = validate({[event.target.id]: event.target.value}, constraints)
    // console.log(err1[event.target.id])

    //debugger
    let err = validate(this.state.contact, constraints)
    if (!err) {
      this.setState({ completed: { ...this.state.completed, 0: true } })
      this.setState({ activeStep: 1 })
    }
    else {
      this.setState({ error: { ...this.state.error, [event.target.id]: err1[event.target.id] }})
    }
  }
  completeSubscription() {
    if(this.state.subscriptions.length > 0 ) {
      this.setState({ completed: { ...this.state.completed, 1: true } })
      this.setState({ activeStep: 2 })
    }
  }

  completeForm = () => {
    this.setState({ sendStatus: true })
    var url = 'https://support.4zzz.org.au/api/newsub';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({contact: this.state.contact, subscriptions: this.state.subscriptions, donation: this.state.donation, payment: this.state.payment }), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => res.json())
    .then(response => this.setState({completeOpen: true}) )
    .catch(error => console.error('Error:', error));
  }

  completeSummary = () => {
    this.setState({ completed: { ...this.state.completed, 3: true } })
    this.setState({ activeStep: 4 })
  }

  completeContact = () => {
    this.setState({ activeStep: 1 })
  }

  addSubscription = (selected) => event => {
		const {subtypes, dailyprizes} = this.props.subscriptions
    let pos = subtypes.map( e => ( e.id )).indexOf(selected)
    let subtype = subtypes[pos]
    if (subtype.daily) { subtype.dailyprize = dailyprizes.find( (i) => ( i.includes(moment().format("dddd MMMM D").toUpperCase() ) ) ) }
    this.setState({ subscriptions: [...this.state.subscriptions, subtype] })
    this.setState({ currentSub: { subtype: '' }})
  }

  removeSubscription = (selected) => event => {
    const subs = this.state.subscriptions
    subs.splice(selected, 1)

    this.setState({ subscriptions: subs })
    this.setState({ currentSub: { subtype: '' }})
  }

  addMembers = (sub, mem) => event => {
    const subs = this.state.subscriptions.slice()
    const members = subs[sub].members.slice()
    members[mem] = event.target.value
    subs[sub] = { ...subs[sub], members: members}
    this.setState({
      subscriptions: subs
    })
  }


   handleDSChange = (item) => {
      debugger;
    }

  handleChange = (section, prop) => event => {
    this.setState({ [section]: { ...this.state[section], [prop]: event.target.value } })
  }

  handleSelectChange = (section) => event => {
    this.setState({ [section]: { ...this.state[section], [event.target.name]: event.target.value } })
  }
  componentDidMount = () => {
    fetch('https://support.4zzz.org.au/api/login').then(res=>res.json()).then(response => this.token = response.token ).catch(error => console.error('Error:', error))
    fetch('https://data.4zzz.org.au/zeddb/programs').then(res=>res.json()).then(response =>  suggestions = response ).catch(error => console.error('Error:', error))
  }


  componentWillUnmount = () => {
    alert('WHAT');
  }

  sendContact = (contact) => () => {
    var url = 'https://support.4zzz.org.au/api/test';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(contact), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => res.json())
    .then(response => this.setState({ contact: response }) )
    .catch(error => console.error('Error:', error));
  }

  sendSubs = (subs, contact) => () => {
    var url = 'https://support.4zzz.org.au/api/test2';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({subs: subs, contact: contact}), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => res.json())
    .then(response => this.setState({subscriptions: response.subscriptions}) )
    .catch(error => console.error('Error:', error));
  }

  sendPayment = (payment, contact) => () => {
    var url = 'https://support.4zzz.org.au/api/cc';
    fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify({payment: payment, contact: contact}), // data can be `string` or {object}!
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }
    }).then(res => res.json())
    .then(response => this.setState({payment: response}) )
    .catch(error => console.error('Error:', error));
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
    if(value) {
      this.setState({ contact: { ...this.state.contact, name: value.subfirstname + ' ' + value.sublastname, subnumber: value.subnumber, mobile: value.submobile, email: value.subemail, address: value.delivery_address.address, suburb: value.delivery_address.locality, postcode: value.delivery_address.postcode }}, () => {
        let err = validate(this.state.contact, constraints)
        if (!err) {
          this.setState({ completed: { ...this.state.completed, 0: true } })
          //this.setState({ activeStep: 1 })
        }
      })
    }
  };

  addName = (sub, type) => event => {
    const subs = this.state.subscriptions.slice()
    subs[sub] = { ...subs[sub], [type]: event.target.value}
    this.setState({
      subscriptions: subs
    })
  }

  favShow = (sub) => event => {
    const subs = this.state.subscriptions.slice()
    subs[sub] = { ...subs[sub], favshow: event.target.value}
    this.setState({
      subscriptions: subs
    })
  }
  favShow2 = (sub) => event => {
    const subs = this.state.subscriptions.slice()
    subs[sub] = { ...subs[sub], favshow: event}
    this.setState({
      subscriptions: subs
    })
  }

  goldPrize = (sub) => event => {
    const subs = this.state.subscriptions.slice()
    subs[sub] = { ...subs[sub], goldprize: event.target.value}
    this.setState({
      subscriptions: subs
    })
  }

  tShirtSize = (sub) => event => {
    const subs = this.state.subscriptions.slice()
    subs[sub] = { ...subs[sub], tshirtsize: event.target.value}
    this.setState({
      subscriptions: subs
    })
  }

  postSubPack = (sub) => event => {
    const subs = this.state.subscriptions.slice()
    subs[sub] = { ...subs[sub], postpack: event.target.value}
    this.setState({
      subscriptions: subs
    })
  }

  handleCheckboxChange = prop => event => {
    this.setState({ [prop]: event.target.checked })
  }



  handleComplete = (value) => event => {
    if (value === 'reset') {
      this.setState(initialState)

    }
    else {
      this.setState({completeOpen: value})
    }
  }

  render(){
    function total(subs, donation){
    //  debugger
      const totals = subs.map( e => ( e.cost + (e && e.postpack === "true" ? 10 : 0 ) ))
      const sums = totals.reduce((a,b) => a + b, 0 )
      const allTotal = sums + (donation && donation.amount ? donation.amount : 0 )
      return allTotal
    }

    return (
      <form autoComplete="off">
      <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} lg={8} xl={8}>
          <Stepper nonLinear activeStep={this.state.activeStep} orientation="vertical">
          <Step key="Contact details">
          <StepButton
            onClick={this.handleStep(0)}
            completed={this.state.completed[0]}
          >
            <Box width={1} component="span" display="block"><span style={{float: 'left'}}>Contact details</span>
            <span style={{float: 'right'}}>{this.state.contact.name ? ': ' + this.state.contact.name : null}</span>
            </Box>
          </StepButton>
            <StepContent>
            <Contact
              handleChange={this.handleChange}
              handleSelectChange={this.handleSelectChange}
              contact={this.state.contact}
              validateSub={this.validateSub}
              error={this.state.error}
              searchSub={this.searchSub}
              searchResults={this.state.searchResults}
              open={this.state.open}
              handleClose={this.handleClose}
             />
             <Button color="primary" disabled={ !this.state.completed['0'] } aria-label="Continue" onClick={this.completeContact}>
               Continue
               <ArrowDownwardIcon />
             </Button>

            {this.debug && <Button onClick={this.sendContact(this.state.contact)}>TEST SEND</Button>}
            </StepContent>
            </Step>
            <Step key="Subscriptions">
              `<StepButton
                onClick={this.handleStep(1)}
                completed={this.state.completed[1]}
              >Subscriptions</StepButton>
              <StepContent>
                <Subscriptions
                  contact={this.state.contact}
                  subscriptions={this.state.subscriptions}
                  currentSub={this.state.currentSub}
                  handleSelectChange={this.handleSelectChange}
                  addMembers={this.addMembers}
                  addSubscription={this.addSubscription}
                  removeSubscription={this.removeSubscription}
                  completeSubscription={this.completeSubscription}
                  debug={this.debug}
                  addName={this.addName}
                  goldPrize={this.goldPrize}
                  tShirtSize={this.tShirtSize}
                  postSubPack={this.postSubPack}
                  favShow={this.favShow}
                  favShow2={this.favShow2}
                  sendSubs={this.sendSubs}
                  suggestions={suggestions}
                 />


              </StepContent>`
            </Step>
            <Step key="Donation">
              `<StepButton
                onClick={this.handleStep(2)}
                completed={this.state.completed[2]}
              >Donation{this.state.donation && this.state.donation.amount > 0 && <span>:  ${this.state.donation.amount}</span>}</StepButton>
              <StepContent>
                <Donation
                  handleChange={this.handleChange}
                  donation={this.state.donation}
                  validateDonation={this.validateDonation}

                />
              </StepContent>`
            </Step>
            <Step key="Summary">
              `<StepButton
                onClick={this.handleStep(3)}
                completed={this.state.completed[3]}
              ><StepLabel>Summary / Total{ total(this.state.subscriptions, this.state.donation) > 0 &&
                 <span>: ${ total(this.state.subscriptions, this.state.donation)}</span>
               }</StepLabel></StepButton>
              <StepContent>
                <Summary
                  contact={this.state.contact}
                  subscriptions={this.state.subscriptions}
                  donation={this.state.donation}
                  total={total}
                  completeSummary={this.completeSummary}
                  handleChange={this.handleChange}
                />
                <Button color="primary" aria-label="Continue" onClick={this.completeSummary}>
                  Continue
                  <ArrowDownwardIcon />
                </Button>
              </StepContent>
              `
            </Step>
            <Step key="Payment">
              `<StepButton
                onClick={this.handleStep(4)}
                completed={this.state.completed[4]}
              >Payment</StepButton>
              <StepContent>
                <Payment
                  contact={this.state.contact}
                  payment={this.state.payment}
                  handleChange={this.handleChange}
                  error={this.state.error}
                  validateSub={this.validateSub}
                  validateCC={this.validateCC}
                  validatePayment={this.validatePayment}
                  currentSub={this.state.currentSub}
                  debug={this.debug}
                  sendPayment={this.sendPayment}
                  completed={this.state.completed}
                  completeForm={this.completeForm}
                />
                <Button style={{marginTop: 24}} disabled={this.state.sendStatus ? true : !(this.state.completed['4'] ? this.state.completed['3'] ? this.state.completed['2'] ? this.state.completed['1'] ? this.state.completed['0'] : false : false : false : false : false) } color="primary" aria-label="Add" onClick={this.completeForm}>
                  Complete subscription and send
                </Button>
                { this.debug && <Button variant="outlined" color="primary" onClick={this.handleComplete(true)}>
                  Test complete box
                </Button> }
                <Complete type={this.state.payment.pledge} open={this.state.completeOpen} onClose={this.handleComplete} />
              </StepContent>`
            </Step>
          </Stepper>
        </Grid>
        <Grid item xs={12} sm={4} lg={4} xl={4} style={{padding: '24px'}}>
          {this.debug && <pre>{JSON.stringify(this.state, null, 4)}</pre> }
          {this.state.activeStep === 0 &&
            <Box fontFamily="h6.fontFamily">
              <Typography style={{fontSize: 14}} color="textSecondary" gutterBottom>
                Contact details
              </Typography>
              <ol>
                <li>Enter first AND last name of subscriber
                </li>
                <li>Ask whether they are a New or Returning Sub
                  <ol>
                    <li>If Returning -
                      <ol>
                        <li>You can search a renewing sub’s existing details by entering their name and hitting the search icon - this will pre-fill all contact details that are on file for this subscriber (including sub number)</li>
                        <li>Confirm existing details with subscriber or update as needed</li>
                      </ol>
                    </li>
                    <li>If New -
                      <ol>
                        <li>Enter subscriber details as prompted</li>
                      </ol>
                    </li>
                    <li>If a subscriber has no email, enter hello@4zzz.org.au </li>
                    <li>If a subscriber has no phone number, write ‘no phone’</li>
                  </ol>
                </li>
                <li>Click the ‘CONTINUE ↓’ icon to move onto the next section. </li>
              </ol>
            </Box>
          }
          {this.state.activeStep === 1 &&
            <Box fontFamily="h6.fontFamily">
              <Typography style={{fontSize: 14}} color="textSecondary" gutterBottom>
                Subscriptions
              </Typography>
              <ol>
                <li>Select subscription type from the drop down menu. You can browse the details of each subscription (cost, sub benefits) without adding it to the order.
                  <ol><li>For details about specific prizes, refer to the website 4zzz.org.au/radiothon or the prize bible.</li></ol>
                </li>
                <li>To add a subscription to the order, click the  ‘+ ADD’ icon. You can remove a subscription after the fact by hitting ‘X DELETE’
                  <ol><li>Fill in the subscriber information including name on card, T shirt sizes and postage options for Passionate and Super Subs, as well as nominating their favourite 4ZZZ program.  If a subscriber does not have a favourite program, type “All Zed Shows” into this field. </li></ol>
                </li>
                <li>Adding multiple subscriptions
                  <ol><li>To add another subscription to the order, simply select the relevant Sub type from the drop down menu, click ‘+ ADD’  icon to add to order and fill in the details.</li></ol>
                </li>
                <li>Once you have added all subscriptions, click the ‘CONTINUE ↓’ icon to move onto the next step.
                </li>
              </ol>
            </Box>
          }
          {this.state.activeStep === 2 &&
            <Box fontFamily="h6.fontFamily">
              <Typography style={{fontSize: 14}} color="textSecondary" gutterBottom>
                Donations
              </Typography>
              <ol>
                <li>Suggested script:
                  <ol>
                    <li>On average it costs $40 to produce an hour of radio on 4ZZZ, making a $40 tax-deductible donation will cover an hour of the carnival on top of your subscription. </li>
                    <li>Any amount you can donate all goes towards connecting and amplifying local communities on 4ZZZ!</li>
                  </ol>
                </li>
                <li>If no donation, add ‘0’ in the donation field </li>
              </ol>
            </Box>
          }
          {this.state.activeStep === 3 &&
            <Box fontFamily="h6.fontFamily">
              <Typography style={{fontSize: 14}} color="textSecondary" gutterBottom>
                Total / Summary
              </Typography>
              <ol>
                <li>Read out order details to confirm
                <ol>
                  <li>If something needs to be changed, click on the relevant heading to make edits. IE if the shirt size needs to be changed, click on the heading ‘2. Subscriptions’; make the changes; then click on the heading ‘4. Summary/Total’ to go back to where you were in the form.</li>
                </ol>
                </li>
              </ol>
            </Box>
          }
          {this.state.activeStep === 4 &&
            <Box fontFamily="h6.fontFamily">
              <Typography style={{fontSize: 14}} color="textSecondary" gutterBottom>
                Payment
              </Typography>
              <ol>
                <li>Select payment method from dropdown menu.</li>
                <ol>
                  <li>Pledges - no payment required now, pledge must be paid by 4pm Monday September 16. Pledges must be paid in person at the station or over the phone. Pledges cannot be paid online.</li>
                  <li>Pay by credit card - collect the credit card information, it is encrypted and destroyed once payment is processed. Payments will be processed within 24 hours. </li>
                </ol>
              </ol>
            </Box>
          }
        </Grid>
      </Grid>
      </Container>
      </form>
    )
  }
}

export default connect( state => ({ subscriptions: state.subscriptions }) ) (Body)
