import React from 'react'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format';

function DonationFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value ? Number(values.value) : null,
          },
        });
      }}
      prefix={'$ '}
      thousandSeparator={' '}
    />
  );
}

const Donation = (props) => (
  <>
    <Typography variant="h5" gutterBottom>Would you like to add a donation?
    </Typography>
    <Typography variant="subtitle1" gutterBottom>All donations over $2 are tax deductible
    </Typography>
    <TextField
      label="Donation Amount"
      id="amount"
      value={props.donation.amount}
      onChange={props.handleChange('donation', 'amount')}
      onBlur={props.validateDonation()}
      InputProps={{
        inputComponent: DonationFormat,
      }}
    />
  </>
)
export default Donation
